import * as React from 'react'
import styled from 'styled-components'

import { i18n } from '@thg-commerce/enterprise-core'
import { margin, padding, Text } from '@thg-commerce/gravity-theme'

const Wrapper = styled.div`
  ${Text('large1', 'alternate')}
  background-color: ${(props) => props.theme.colors.palette.greys.lighter};
  color: ${(props) => props.theme.colors.palette.greys.darker};
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100px;
  border-radius: 10px;
  ${margin({
    top: 3,
    bottom: 3,
    left: 2,
    right: 2,
  })}
`

const Message = styled.p`
  width: fit-content;
  ${padding({
    top: 1,
    bottom: 1,
    left: 2,
    right: 2,
  })}
`

export const CookieMessageFallback = () => {
  return (
    <Wrapper data-testid="cookie-message-fallback">
      <Message>{i18n('socialembed.cookie.fallback.message')}</Message>
    </Wrapper>
  )
}
