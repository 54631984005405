import * as React from 'react'
import loadable from '@loadable/component'

import { PaymentIcon as PaymentIconType } from '@thg-commerce/enterprise-config'
import { i18n } from '@thg-commerce/enterprise-core'
import { styled, useTheme } from '@thg-commerce/enterprise-theme'

const Afterpay = loadable(
  () =>
    import('@thg-commerce/gravity-icons/src/components/PaymentIcons/Afterpay'),
)
const AmazonPay = loadable(
  () =>
    import('@thg-commerce/gravity-icons/src/components/PaymentIcons/AmazonPay'),
)
const AliPay = loadable(
  () =>
    import('@thg-commerce/gravity-icons/src/components/PaymentIcons/AliPay'),
)
const AlipayPlus = loadable(
  () =>
    import(
      '@thg-commerce/gravity-icons/src/components/PaymentIcons/AlipayPlus'
    ),
)
const AmericanExpress = loadable(
  () =>
    import(
      '@thg-commerce/gravity-icons/src/components/PaymentIcons/AmericanExpress'
    ),
)
const ApplePay = loadable(
  () =>
    import('@thg-commerce/gravity-icons/src/components/PaymentIcons/ApplePay'),
)
const Arvato = loadable(
  () =>
    import('@thg-commerce/gravity-icons/src/components/PaymentIcons/Arvato'),
)
const Atome = loadable(
  () => import('@thg-commerce/gravity-icons/src/components/PaymentIcons/Atome'),
)
const Bancontact = loadable(
  () =>
    import(
      '@thg-commerce/gravity-icons/src/components/PaymentIcons/Bancontact'
    ),
)
const ClearPay = loadable(
  () =>
    import('@thg-commerce/gravity-icons/src/components/PaymentIcons/ClearPay'),
)
const CustomIcon = loadable(
  () =>
    import(
      '@thg-commerce/gravity-icons/src/components/CustomIcon/CustomIcon'
    ).then((mod) => mod.CustomIcon),
  { fallback: <div style={{ width: 60, height: 40 }} /> },
)
const DinersClub = loadable(
  () =>
    import(
      '@thg-commerce/gravity-icons/src/components/PaymentIcons/DinersClub'
    ),
)
const Discover = loadable(
  () =>
    import('@thg-commerce/gravity-icons/src/components/PaymentIcons/Discover'),
)
const GiftCard = loadable(
  () =>
    import('@thg-commerce/gravity-icons/src/components/PaymentIcons/GiftCard'),
)
const GiroPay = loadable(
  () =>
    import('@thg-commerce/gravity-icons/src/components/PaymentIcons/GiroPay'),
)
const GooglePay = loadable(
  () =>
    import('@thg-commerce/gravity-icons/src/components/PaymentIcons/GooglePay'),
)
const Ideal = loadable(
  () => import('@thg-commerce/gravity-icons/src/components/PaymentIcons/Ideal'),
)
const Interac = loadable(
  () =>
    import('@thg-commerce/gravity-icons/src/components/PaymentIcons/Interac'),
)
const Jcb = loadable(
  () => import('@thg-commerce/gravity-icons/src/components/PaymentIcons/Jcb'),
)
const Kcp = loadable(
  () => import('@thg-commerce/gravity-icons/src/components/PaymentIcons/Kcp'),
)
const Klarna = loadable(
  () =>
    import('@thg-commerce/gravity-icons/src/components/PaymentIcons/Klarna'),
)
const LayBuy = loadable(
  () =>
    import('@thg-commerce/gravity-icons/src/components/PaymentIcons/LayBuy'),
)
const Mode = loadable(
  () => import('@thg-commerce/gravity-icons/src/components/PaymentIcons/Mode'),
)
const MolPay = loadable(
  () =>
    import('@thg-commerce/gravity-icons/src/components/PaymentIcons/MolPay'),
)
const Multibanco = loadable(
  () =>
    import(
      '@thg-commerce/gravity-icons/src/components/PaymentIcons/Multibanco'
    ),
)
const NewPay = loadable(
  () =>
    import('@thg-commerce/gravity-icons/src/components/PaymentIcons/NewPay'),
)
const Openpay = loadable(
  () =>
    import('@thg-commerce/gravity-icons/src/components/PaymentIcons/Openpay'),
)
const Payvyne = loadable(
  () =>
    import('@thg-commerce/gravity-icons/src/components/PaymentIcons/Payvyne'),
)
const Paypal = loadable(
  () =>
    import('@thg-commerce/gravity-icons/src/components/PaymentIcons/Paypal'),
)
const PaypalIn_3 = loadable(
  () =>
    import('@thg-commerce/gravity-icons/src/components/PaymentIcons/PaypalIn3'),
)
const Paytm = loadable(
  () => import('@thg-commerce/gravity-icons/src/components/PaymentIcons/Paytm'),
)
const PrimeiroPay = loadable(
  () =>
    import(
      '@thg-commerce/gravity-icons/src/components/PaymentIcons/PrimeiroPay'
    ),
)
const QiwiWallet = loadable(
  () =>
    import(
      '@thg-commerce/gravity-icons/src/components/PaymentIcons/QiwiWallet'
    ),
)
const Quadpay = loadable(
  () =>
    import('@thg-commerce/gravity-icons/src/components/PaymentIcons/Quadpay'),
)
const Sepa = loadable(
  () => import('@thg-commerce/gravity-icons/src/components/PaymentIcons/Sepa'),
)
const Sezzle = loadable(
  () =>
    import('@thg-commerce/gravity-icons/src/components/PaymentIcons/Sezzle'),
)
const Sofort = loadable(
  () =>
    import('@thg-commerce/gravity-icons/src/components/PaymentIcons/Sofort'),
)
const SplitIt = loadable(
  () =>
    import('@thg-commerce/gravity-icons/src/components/PaymentIcons/SplitIt'),
)
const Spotii = loadable(
  () =>
    import('@thg-commerce/gravity-icons/src/components/PaymentIcons/Spotii'),
)
const Trustly = loadable(
  () =>
    import('@thg-commerce/gravity-icons/src/components/PaymentIcons/Trustly'),
)
const TrustPay = loadable(
  () =>
    import('@thg-commerce/gravity-icons/src/components/PaymentIcons/TrustPay'),
)
const Union = loadable(
  () => import('@thg-commerce/gravity-icons/src/components/PaymentIcons/Union'),
)
const VisaCredit = loadable(
  () => import('@thg-commerce/gravity-icons/src/components/PaymentIcons/Visa'),
)
const WeChatPay = loadable(
  () =>
    import('@thg-commerce/gravity-icons/src/components/PaymentIcons/WeChatPay'),
)
const Zippay = loadable(
  () =>
    import('@thg-commerce/gravity-icons/src/components/PaymentIcons/Zippay'),
)

const FrasersPlus = loadable(
  () =>
    import(
      '@thg-commerce/gravity-icons/src/components/PaymentIcons/FrasersPlus'
    ),
)

export interface PaymentIconProps {
  icon: PaymentIconType
  includeBorder?: boolean
  className?: string
  onClick?: () => void
  'data-testid'?: string
}

const ICONS: {
  [icon in PaymentIconType]: {
    component: React.ComponentType
    labelProperty: string
  }
} = {
  [PaymentIconType.AFTER_PAY]: {
    component: Afterpay,
    labelProperty: 'paymentmethods.afterpay.svg.title',
  },
  [PaymentIconType.AMAZONPAY]: {
    component: AmazonPay,
    labelProperty: 'paymentmethods.amazonpay.svg.title',
  },
  [PaymentIconType.ALI_PAY]: {
    component: AliPay,
    labelProperty: 'paymentmethods.alipay.svg.title',
  },
  [PaymentIconType.ALI_PAY_PLUS]: {
    component: AlipayPlus,
    labelProperty: 'paymentmethods.alipay.plus.svg.title',
  },
  [PaymentIconType.ATOME]: {
    component: Atome,
    labelProperty: 'paymentmethods.atome.svg.title',
  },
  [PaymentIconType.SPOTII]: {
    component: Spotii,
    labelProperty: 'paymentmethods.spotii.svg.title',
  },
  [PaymentIconType.AMERICAN_EXPRESS]: {
    component: AmericanExpress,
    labelProperty: 'paymentmethods.american.express.svg.title',
  },
  [PaymentIconType.APPLE_PAY]: {
    component: ApplePay,
    labelProperty: 'paymentmethods.apple.pay.svg.title',
  },
  [PaymentIconType.ARVATO]: {
    component: Arvato,
    labelProperty: 'paymentmethods.arvato.svg.title',
  },
  [PaymentIconType.BANCONTACT]: {
    component: Bancontact,
    labelProperty: 'paymentmethods.bancontact.svg.title',
  },
  [PaymentIconType.CLEAR_PAY]: {
    component: ClearPay,
    labelProperty: 'paymentmethods.clearpay.svg.title',
  },
  [PaymentIconType.DINERS_CLUB]: {
    component: DinersClub,
    labelProperty: 'paymentmethods.diners.club.svg.title',
  },
  [PaymentIconType.DISCOVER]: {
    component: Discover,
    labelProperty: 'paymentmethods.discover.svg.title',
  },
  [PaymentIconType.GIFT_CARD]: {
    component: GiftCard,
    labelProperty: 'paymentmethods.gift.card.svg.title',
  },
  [PaymentIconType.GIRO_PAY]: {
    component: GiroPay,
    labelProperty: 'paymentmethods.giropay.svg.title',
  },
  [PaymentIconType.GOOGLE_PAY]: {
    component: GooglePay,
    labelProperty: 'paymentmethods.google.pay.svg.title',
  },
  [PaymentIconType.IDEAL]: {
    component: Ideal,
    labelProperty: 'paymentmethods.ideal.svg.title',
  },
  [PaymentIconType.INTERAC]: {
    component: Interac,
    labelProperty: 'paymentmethods.interac.svg.title',
  },
  [PaymentIconType.JCB]: {
    component: Jcb,
    labelProperty: 'paymentmethods.jcb.svg.title',
  },
  [PaymentIconType.KCP]: {
    component: Kcp,
    labelProperty: 'paymentmethods.kcp.svg.title',
  },
  [PaymentIconType.KLARNA]: {
    component: Klarna,
    labelProperty: 'paymentmethods.klarna.svg.title',
  },
  [PaymentIconType.LAY_BUY]: {
    component: LayBuy,
    labelProperty: 'paymentmethods.laybuy.svg.title',
  },
  [PaymentIconType.MODE]: {
    component: Mode,
    labelProperty: 'paymentmethods.mode.svg.title',
  },
  [PaymentIconType.MOL_PAY]: {
    component: MolPay,
    labelProperty: 'paymentmethods.molpay.svg.title',
  },
  [PaymentIconType.MULTIBANCO]: {
    component: Multibanco,
    labelProperty: 'paymentmethods.multibanco.svg.title',
  },
  [PaymentIconType.NEWPAY]: {
    component: NewPay,
    labelProperty: 'paymentmethods.newpay.svg.title',
  },
  [PaymentIconType.OPEN_PAY]: {
    component: Openpay,
    labelProperty: 'paymentmethods.openpay.svg.title',
  },
  [PaymentIconType.PAYVYNE]: {
    component: Payvyne,
    labelProperty: 'paymentmethods.payvyne.svg.title',
  },
  [PaymentIconType.PAYPAL]: {
    component: Paypal,
    labelProperty: 'paymentmethods.paypal.svg.title',
  },
  [PaymentIconType.PAYPAL_IN_3]: {
    component: PaypalIn_3,
    labelProperty: 'paymentmethods.paypalin3.svg.title',
  },
  [PaymentIconType.PAYTM]: {
    component: Paytm,
    labelProperty: 'paymentmethods.paytm.svg.title',
  },
  [PaymentIconType.PRIMEIRO_PAY]: {
    component: PrimeiroPay,
    labelProperty: 'paymentmethods.primeiropay.svg.title',
  },
  [PaymentIconType.QIWI_WALLET]: {
    component: QiwiWallet,
    labelProperty: 'paymentmethods.qiwi_wallet.svg.title',
  },
  [PaymentIconType.QUAD_PAY]: {
    component: Quadpay,
    labelProperty: 'paymentmethods.quadpay.svg.title',
  },
  [PaymentIconType.SEPA]: {
    component: Sepa,
    labelProperty: 'paymentmethods.sepa.svg.title',
  },
  [PaymentIconType.SEZZLE]: {
    component: Sezzle,
    labelProperty: 'paymentmethods.sezzle.svg.title',
  },
  [PaymentIconType.SOFORT]: {
    component: Sofort,
    labelProperty: 'paymentmethods.sofort.svg.title',
  },
  [PaymentIconType.SPLIT_IT]: {
    component: SplitIt,
    labelProperty: 'paymentmethods.splitit.svg.title',
  },
  [PaymentIconType.TRUSTLY]: {
    component: Trustly,
    labelProperty: 'paymentmethods.trustly.svg.title',
  },
  [PaymentIconType.TRUST_PAY]: {
    component: TrustPay,
    labelProperty: 'paymentmethods.trustpay.svg.title',
  },
  [PaymentIconType.UNION_PAY]: {
    component: Union,
    labelProperty: 'paymentmethods.unionpay.svg.title',
  },
  [PaymentIconType.WE_CHAT_PAY]: {
    component: WeChatPay,
    labelProperty: 'paymentmethods.wechat.pay.svg.title',
  },
  [PaymentIconType.ZIP_PAY]: {
    component: Zippay,
    labelProperty: 'paymentmethods.zippay.svg.title',
  },
  [PaymentIconType.VISA_CHECKOUT]: {
    component: VisaCredit, // TODO: update
    labelProperty: 'paymentmethods.visacheckout.svg.title',
  },
  [PaymentIconType.FRASERS_PLUS]: {
    component: FrasersPlus,
    labelProperty: 'paymentmethods.frasersplus.svg.title',
  },
}

const PaymentIconWrapper = styled.div`
  width: 60px;
  height: 40px;
  border-radius: 4px;
  border: solid 1px ${(props) => props.theme.colors.palette.greys.light};
  background-color: ${(props) => props.theme.colors.palette.greys.white};
  display: flex;
  align-items: center;
  justify-content: center;

  &:focus {
    outline: none;
    border: 1px solid ${(props) => props.theme.colors.palette.brand.base};
  }
`

const googlePayIsReadyToPayRequest: google.payments.api.IsReadyToPayRequest = {
  apiVersion: 2,
  apiVersionMinor: 0,
  allowedPaymentMethods: [
    {
      type: 'CARD',
      parameters: {
        allowedAuthMethods: ['PAN_ONLY', 'CRYPTOGRAM_3DS'],
        allowedCardNetworks: ['AMEX', 'DISCOVER', 'JCB', 'MASTERCARD', 'VISA'],
      },
    },
  ],
}

declare global {
  interface Window {
    ApplePaySession?: any
  }
}

const GooglePayCheck = (props: {
  availableCallback: (available: boolean) => void
}) => {
  const onLoad = async () => {
    const paymentsClient = new window.google.payments.api.PaymentsClient({
      environment: 'PRODUCTION',
    })
    try {
      const response = await paymentsClient.isReadyToPay(
        googlePayIsReadyToPayRequest,
      )
      props.availableCallback(response.result)
    } catch {}
  }

  React.useEffect(() => {
    const head = document.getElementsByTagName('head')[0]
    const script = document.createElement('script')
    script.type = 'text/javascript'
    script.async = false
    script.src = 'https://pay.google.com/gp/p/js/pay.js'
    script.onload = onLoad
    head.appendChild(script)
  }, [])

  return null
}

const defaultShouldRender = (icon: PaymentIconType) => {
  if (icon === PaymentIconType.APPLE_PAY) {
    return window.ApplePaySession !== undefined
  }
  if (icon === PaymentIconType.GOOGLE_PAY) {
    return false
  }
  return true
}

export const PaymentIcon: React.FunctionComponent<PaymentIconProps> = (
  props,
) => {
  const theme = useTheme()

  const label = i18n(
    ICONS[props.icon]
      ? ICONS[props.icon]?.labelProperty || ''
      : 'placeholderprop',
  )
  const [shouldRender, setShouldRender] = React.useState(
    defaultShouldRender(props.icon),
  )

  if (!ICONS[props.icon]) {
    return null
  }

  const Icon = ICONS[props.icon]!.component
  const paymentIcon =
    theme.patterns.footer.copyrightAndPayments.paymentIcons[
      props.icon.toUpperCase()
    ]

  const iconElement = (
    <React.Fragment>
      {shouldRender &&
        (paymentIcon?.svgPath !== ('' || undefined) ? (
          <CustomIcon
            path={paymentIcon.svgPath}
            fillColor={paymentIcon.color}
            rect={
              paymentIcon.rectBackgroundFill &&
              paymentIcon.rectStrokeFill && {
                x: '0.5',
                y: '0.5',
                width: '59',
                height: '39',
                rx: '3.5',
                fill: paymentIcon.rectBackgroundFill,
                stroke: paymentIcon.rectStrokeFill,
              }
            }
            height={paymentIcon.height}
            width={paymentIcon.width}
            viewBox={paymentIcon.viewBox}
          />
        ) : (
          <Icon aria-label={label} />
        ))}
      {props.icon === PaymentIconType.GOOGLE_PAY && (
        <GooglePayCheck
          availableCallback={(available) => setShouldRender(available)}
        />
      )}
    </React.Fragment>
  )

  if (!shouldRender) {
    return iconElement
  }

  return props.includeBorder ? (
    <PaymentIconWrapper
      className={props.className}
      as={props.onClick ? 'button' : undefined}
      onClick={props.onClick && props.onClick}
      data-testid={props['data-testid']}
    >
      {iconElement}
    </PaymentIconWrapper>
  ) : (
    <span className={props.className} data-testid={props['data-testid']}>
      {iconElement}
    </span>
  )
}
