import * as React from 'react'

import { ImageCarouselProps } from './types'

import { StyledImage, CarouselInnerWrapper } from './styles'
import { Carousel } from '../../Carousel'

export const ImageCarousel = (props: ImageCarouselProps) => {
  if (props.urls.length === 0) {
    return null
  }

  const carouselItems = React.useMemo(() => {
    return props.urls.map((url, index) => {
      return (
        <StyledImage
          width="669"
          height="669"
          key={url.url}
          src={[
            {
              url: url.url,
            },
          ]}
          alt={url.alt}
          isAmp={false}
          tag={url.tag}
          aspectRatio={props.aspectRatio}
          fetchPriority={
            props.onHydrateVisibleFetchPriority &&
            index < (props.itemsPerSlide || 1)
              ? props.onHydrateVisibleFetchPriority
              : undefined
          }
        />
      )
    })
  }, [props.urls, props.aspectRatio, props.onHydrateVisibleFetchPriority])

  return (
    <CarouselInnerWrapper fullscreen={props.fullscreen}>
      <Carousel
        items={carouselItems}
        i18n={props?.i18nText}
        indicatorStyle="hidden"
        itemsPerSlide={props.itemsPerSlide || 1}
        hideSlidePreview={true}
        hideControls={true}
        loopSlides={true}
        slideTo={props.slideTo}
        zoomEnabled={props.zoomEnabled}
        zoomClickable={props.zoomClickable}
        zoomOnClick={props.zoomOnClick}
        swipeable={true}
        onChange={props.onChange}
        style={{ height: '100%' }}
        itemGapSpacing={props.itemGapSpacing}
        controls={props.controls}
        overflow={props.overflow}
        onSwipe={props.onSwipe}
        onNavClick={props.onNavClick}
        onThumbnailClick={props.onThumbnailClick}
      />
    </CarouselInnerWrapper>
  )
}
