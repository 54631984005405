import { FallbackDataLoader, RecommendationSourceType } from '../../types'
import { BasketRecommendationsProductBlockList } from './BasketRecommendationsProductBlockList.graphql'

export const loadBasketRecommendations: FallbackDataLoader = async (
  source,
  apolloClient,
) => {
  if (source.type !== RecommendationSourceType.BASKET_PAGE) {
    return []
  }

  const { data } = await apolloClient.query({
    query: BasketRecommendationsProductBlockList,
    variables: {
      id: source.id,
      shippingDestination: source.shippingDestination,
      currency: source.currency,
      recommendationsType: source.type,
    },
  })

  return data?.basketRecommendationsProductBlockList ?? []
}
