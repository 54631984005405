import * as React from 'react'

import { styled, mq, spacing } from '@thg-commerce/enterprise-theme'
import { NewSkeleton as Skeleton } from '@thg-commerce/gravity-elements'

const MobileContainer = styled(Skeleton)`
  height: 144px;

  ${(props) => mq(props.theme.breakpointUtils.map, 'sm')} {
    display: none;
  }
`
const TabletContainer = styled(Skeleton)`
  display: none;

  ${(props) => mq(props.theme.breakpointUtils.map, 'sm')} {
    display: block;
    height: 56px;
    margin-top: ${spacing(4)};
  }
`

const MobileDiscount = () => {
  return (
    <MobileContainer>
      <rect x="0" y="32" rx="3" ry="3" width="100%" height="4" />
      <rect x="0" y="32" rx="3" ry="3" width="4" height="48" />
      <rect x="0" y="80" rx="3" ry="3" width="100%" height="4" />
      <rect
        x="0"
        y="32"
        rx="3"
        ry="3"
        width="4"
        height="48"
        style={{ transform: 'translate(calc(100% - 4px), 0)' }}
      />
      <rect x="0" y="96" rx="3" ry="3" width="100%" height="48" />
    </MobileContainer>
  )
}

const TabletDiscount = () => {
  return (
    <TabletContainer>
      <rect
        x="0"
        y="0"
        rx="3"
        ry="3"
        width="97"
        height="48"
        style={{ transform: 'translate(calc(100% - 97px), 0)' }}
      />
      <rect
        x="0"
        y="0"
        rx="3"
        ry="3"
        width="4"
        height="48"
        style={{ transform: 'translate(calc(100% - 109px), 0)' }}
      />
      <rect
        x="0"
        y="0"
        rx="3"
        ry="3"
        width="345"
        height="4"
        style={{ transform: 'translate(calc(100% - 452px), 0)' }}
      />
      <rect
        x="0"
        y="45"
        rx="3"
        ry="3"
        width="345"
        height="4"
        style={{ transform: 'translate(calc(100% - 452px), 0)' }}
      />
      <rect
        x="0"
        y="0"
        rx="3"
        ry="3"
        width="4"
        height="48"
        style={{ transform: 'translate(calc(100% - 452px), 0)' }}
      />
    </TabletContainer>
  )
}

export const Discount = () => {
  return (
    <React.Fragment>
      <MobileDiscount />
      <TabletDiscount />
    </React.Fragment>
  )
}
