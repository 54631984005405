import * as React from 'react'
import loadable from '@loadable/component'

import { i18n } from '@thg-commerce/enterprise-core'
import { Text, styled, spacing } from '@thg-commerce/gravity-theme'
import {
  BasketItemMessage,
  BasketItemMessageType,
  FulfilmentMethod,
} from '@thg-commerce/enterprise-network/src/generated/graphql'

const Icon = loadable(
  () => import('@thg-commerce/gravity-icons/src/components/IconError'),
  { ssr: true, fallback: <div style={{ width: 24, height: 24 }} /> },
)

const MessageContainer = styled.div`
  display: flex;
  align-items: center;
`

const ErrorText = styled.p`
  ${Text('bodyText', 'alternate')};
  color: ${(props) => props.theme.colors.error.base};
  padding-left: ${spacing(1)};
`

export interface MessageProps {
  messages: BasketItemMessage[]
  fulfilmentMethod: FulfilmentMethod
}

export const Message = (props: MessageProps) => {
  const i18nText = {
    HOME_DELIVERY_FULFILMENT_ISSUE: i18n(
      'basket.item.homedelivery.fulfilment.error',
    ),
    HOME_DELIVERY_PRODUCT_ISSUE: i18n('basket.item.homedelivery.product.error'),
    COLLECT_IN_STORE_FULFILMENT_ISSUE: i18n(
      'basket.item.storecollection.fulfilment.error',
    ),
    COLLECT_IN_STORE_PRODUCT_ISSUE: i18n(
      'basket.item.storecollection.product.error',
    ),
    COLLECT_IN_STORE_NOT_ENOUGH_STOCK: i18n(
      'basket.item.storecollection.insufficient.stock',
    ),
    DELIVER_TO_STORE_FULFILMENT_ISSUE: i18n(
      'basket.item.delivertostore.fulfilment.error',
    ),
    DELIVER_TO_STORE_PRODUCT_ISSUE: i18n(
      'basket.item.delivertostore.product.error',
    ),
    COLLECT_IN_STORE_NOT_IN_STOCK: i18n('basket.item.storecollection.no.stock'),
  }

  const itemErrors = props.messages.filter(
    (message) =>
      message.type === BasketItemMessageType.ProductIssue ||
      message.type === BasketItemMessageType.NotEnoughStock ||
      message.type === BasketItemMessageType.FulfilmentIssue,
  )

  if (!itemErrors.length) return null

  const errorMessages = itemErrors.map(
    (itemError) => i18nText[`${props.fulfilmentMethod}_${itemError.type}`],
  )

  return (
    <React.Fragment>
      {errorMessages.map((message) => {
        return (
          <MessageContainer data-testid="basket-item-message-container">
            <div>
              <Icon />
            </div>
            <ErrorText>{message}</ErrorText>
          </MessageContainer>
        )
      })}
    </React.Fragment>
  )
}
