import * as React from 'react'

import { EnterpriseContext, i18n } from '@thg-commerce/enterprise-core'
import { useProductDescriptionAccordionTransformer } from '@thg-commerce/enterprise-components'
import { ModalRenderer } from '@thg-commerce/enterprise-modal'
import { styled } from '@thg-commerce/enterprise-theme'
import { pushToDataLayer } from '@thg-commerce/enterprise-metrics/src/data_layer'
import { ProductImage } from '@thg-commerce/enterprise-network/src/generated/graphql'

import {
  ImageGalleryProps,
  ProductInformationModal,
  ProductInformationModalProps,
} from '@thg-commerce/gravity-patterns'
import { ProductAccordionThemeInterface } from '@thg-commerce/gravity-patterns/ProductDescriptionAccordion/types'

import { Product, SelectYourSampleContext } from '../SelectYourSampleContext'

const StyledProductInformationModal = styled(ProductInformationModal)`
  gap: 0;
`
interface InformationModalPresenterProps {
  productDescriptionTheme?: ProductAccordionThemeInterface
}
export const InformationModalPresenter = (
  props: InformationModalPresenterProps,
) => {
  const { informationModalPresenter } = React.useContext(
    SelectYourSampleContext,
  )
  const { headerHeightRef } = React.useContext(EnterpriseContext)
  const [visible, setVisible] = React.useState(false)
  const [product, setProduct] = React.useState<Product | undefined>()
  const productDescriptionTransformer = useProductDescriptionAccordionTransformer()

  const modalCloseI18nText = {
    closeAriaLabel: i18n('general.modal.close.button.arialabel'),
    closeLabel: i18n('general.modal.close.button.label'),
  }

  const {
    value: [getHeaderHeight],
  } = headerHeightRef

  const imageGalleryI18nText: ImageGalleryProps['i18nText'] = {
    carouselI18nText: {
      leftScrollLabel: i18n('carousel.controls.left.arialabel'),
      rightScrollLabel: i18n('carousel.controls.right.arialabel'),
    },
    thumbnailButtonAriaLabel: i18n('imagethumbnails.arialabel.text'),
    fullscreenI18nText: {
      enterFullscreenText: i18n('imagegallery.fullscreen.enter.text'),
      exitFullscreenText: i18n('imagegallery.fullscreen.exit.text'),
    },
    announcerI18nText: {
      imageChangeAnnouncerText: i18n('imagegallery.announcer.imagechange.text'),
      zoomLevelAnnouncerText: i18n('imagegallery.announcer.zoomlevel.text'),
      fullscreenEnterAnnouncerText: i18n(
        'imagegallery.announcer.fullscreenview.enter.text',
      ),
      fullscreenExitAnnouncerText: i18n(
        'imagegallery.announcer.fullscreenview.exit.text',
      ),
      panI18nText: {
        imagePanAnnouncerText: i18n('imagegallery.announcer.panning.text'),
        leftText: i18n('imagegallery.announcer.pan.direction.left.text'),
        rightText: i18n('imagegallery.announcer.pan.direction.right.text'),
        downText: i18n('imagegallery.announcer.pan.direction.down.text'),
        upText: i18n('imagegallery.announcer.pan.direction.up.text'),
      },
    },
    fullscreenIndexI18nText: i18n('imagegallery.fullscreen.index.text'),
    zoomInLabel: i18n('imagegallery.controls.zoomin.arialabel'),
    zoomOutLabel: i18n('imagegallery.controls.zoomout.arialabel'),
    accessibilityLabel: i18n('imagegallery.accessibilitylabel.text'),
  }

  informationModalPresenter.current = (product: Product) => {
    setVisible(true)
    setProduct(product)
  }

  if (!visible || !product) {
    return null
  }

  pushToDataLayer({
    type: 'elysiumEvent',
    eventData: {
      eventAction: 'Viewed Product Info',
      eventCategory: 'freeProductModal',
      eventLabel: 'product Id',
      eventLabelValue: `${product.sku}`,
    },
  })

  const { customTopItems, customBottomItems } = productDescriptionTransformer(
    product.descriptionContent,
    true,
    product.sku,
  )

  const modalProduct: ProductInformationModalProps['product'] = {
    description: {
      customBottomItems,
      customTopItems,
      items:
        product.descriptionContent?.items.map((item) => ({
          ...item,
          title: i18n(`product.content.${item.titleKey}.title.text`),
        })) || [],
      details: {
        title: i18n('product.content.productdetails.title.text'),
        items:
          product.descriptionContent?.details.map((item) => ({
            ...item,
            title: i18n(`product.content.${item.titleKey}.title.text`),
          })) || undefined,
      },
      theme: props.productDescriptionTheme,
    },
    title: product.title,
    sku: product.sku.toString(),
  }

  const modalImageGallery: ProductInformationModalProps['imageGallery'] = {
    urls: (product.images || []).reduce(
      (
        productImages: ProductInformationModalProps['imageGallery']['urls'],
        image: ProductImage,
      ) => {
        productImages.thumbnails.push({
          url: image.thumbnail,
          alt: product.title,
          __typename: 'ProductGalleryImage',
        })
        productImages.images.push({
          url: image.largeProduct,
          alt: product.title,
          __typename: 'ProductGalleryImage',
        })
        productImages.zoom.push({
          url: image.zoom,
          alt: product.title,
          __typename: 'ProductGalleryImage',
        })
        return productImages
      },
      {
        thumbnails: [],
        images: [],
        zoom: [],
        __typename: 'ProductGallery',
      },
    ),
    i18nText: imageGalleryI18nText,
  }

  return (
    <ModalRenderer
      onClose={() => setVisible(false)}
      gridColSpan={[12, 6, 8, 8]}
      headerOffset={getHeaderHeight()}
      closeI18nText={modalCloseI18nText}
      contentPadding="0 0 16px 0"
      showHeader={true}
      stickyHeader={true}
      renderContent={() => (
        <StyledProductInformationModal
          product={modalProduct}
          imageGallery={modalImageGallery}
          productDescriptionTheme={props.productDescriptionTheme}
        />
      )}
    />
  )
}
