import * as React from 'react'

import { IconStyling } from '@thg-commerce/gravity-theme'

import {
  IconContainer,
  RemoveItem,
  RemoveItemContainer,
  StyledClose,
} from '../styles'

interface RemoveBasketItemProps {
  freeGift: boolean
  removeProductLabel: string
  removeItemLabel: string
  icon?: IconStyling
  onClick: () => void
  productTitle?: string
}

export const RemoveBasketItem = (props: RemoveBasketItemProps) => {
  return (
    <RemoveItemContainer>
      {!props.freeGift && (
        <RemoveItem
          onClick={props.onClick}
          aria-label={
            props.productTitle
              ? `${props.removeProductLabel} ${props.productTitle}`
              : props.removeItemLabel
          }
          data-testid="remove-product"
        >
          <IconContainer>
            {props.icon?.svgPath ? (
              <svg
                height={props.icon.height || '24px'}
                width={props.icon.width || '24px'}
                viewBox={props.icon.viewBox || '0 0 24 24'}
              >
                <path d={props.icon.svgPath} fillRule="evenodd" />
              </svg>
            ) : (
              <StyledClose />
            )}
          </IconContainer>
        </RemoveItem>
      )}
    </RemoveItemContainer>
  )
}
