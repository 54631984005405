import * as React from 'react'

import { useAddToBasket } from '@thg-commerce/enterprise-core'
import { styled } from '@thg-commerce/enterprise-theme'
import { Button, SafeHtml } from '@thg-commerce/gravity-elements'

import { MessageTransformer } from '../types'

const LINK_REGEX = /(<a.*?>.*?<\/a>)/g
const BUY_LIST_LINK_PARTS_REGEX = /<a.*href="(.*buylist=.+)".*?>(.+)<\/a>/

const StyledSafeHtml = styled(SafeHtml)`
  display: inline;

  b {
    color: inherit;
    font-weight: bold;
  }
`

const StyledButton = styled(Button)`
  /* Workaround for button not styled like PlatformMessage due to SafeHtml */
  > * {
    color: inherit;
    font-weight: inherit;
  }
`

export const upsellMessageTransformer: MessageTransformer = () => ({
  message,
  StyledPlatformMessage,
}) => {
  const { execute: addToBasket } = useAddToBasket({
    forceAddToBasket: true,
  })

  const matches = message?.split(LINK_REGEX)
  if (!matches?.length) {
    return null
  }

  const { upsellMessage, announcerUpsellMessage } = matches.reduce<{
    upsellMessage: React.ReactElement[]
    announcerUpsellMessage: string[]
  }>(
    (accumulator, messagePart, index) => {
      const buyLinkParts = messagePart.match(BUY_LIST_LINK_PARTS_REGEX)
      if (!buyLinkParts) {
        accumulator.announcerUpsellMessage.push(messagePart)
        accumulator.upsellMessage.push(
          <StyledSafeHtml
            content={messagePart}
            key={index}
            __dangerouslyAllowedTags={['b']}
          />,
        )
        return accumulator
      }

      const [, url, linkText] = buyLinkParts

      const parsedURL = new URL(url, 'http://localhost')

      const buyLink = parsedURL.searchParams.get('buylist')
      if (!buyLink) {
        accumulator.announcerUpsellMessage.push(messagePart)
        accumulator.upsellMessage.push(
          <StyledSafeHtml content={messagePart} key={index} />,
        )
        return accumulator
      }

      const products = buyLink.split(',')
      const firstProduct = products[0]
      const [sku, quantity] = firstProduct.split(':')

      if (
        !sku ||
        isNaN(parseInt(sku, 10)) ||
        !quantity ||
        isNaN(parseInt(quantity, 10))
      ) {
        accumulator.announcerUpsellMessage.push(messagePart)
        accumulator.upsellMessage.push(
          <StyledSafeHtml content={messagePart} key={index} />,
        )
        return accumulator
      }

      accumulator.upsellMessage.push(
        <StyledButton
          emphasis="low"
          onClick={() => {
            addToBasket([{ sku, quantity: parseInt(quantity, 10) }])
          }}
          key={index}
        >
          <SafeHtml content={linkText} />
        </StyledButton>,
      )

      return accumulator
    },
    { upsellMessage: [], announcerUpsellMessage: [] },
  )

  return (
    <StyledPlatformMessage
      type="info"
      text={announcerUpsellMessage.join(' ')}
      content={upsellMessage}
    />
  )
}
