import * as React from 'react'

import { ProductGallery } from '@thg-commerce/enterprise-network/src/ApolloProvider/resolvers/Types/Product'
import { Grid, GridItem } from '@thg-commerce/gravity-system'
import {
  Direction,
  mq,
  spacing,
  styled,
  Text,
} from '@thg-commerce/gravity-theme'

import { CarouselItem } from '../Carousel/styles'
import { ImageGallery } from '../ImageGallery'
import { ImageGalleryProps, Position } from '../ImageGallery/types'
import {
  ProductImageThumbnailsWrapper,
  StyledImage,
  StyledThumbnailList,
} from '../ImageGallery/ImageThumbnails/styles'
import {
  ProductDescriptionAccordion,
  ProductDescriptionAccordionProps,
} from '../ProductDescriptionAccordion'
import { ProductAccordionThemeInterface } from '../ProductDescriptionAccordion/types'

export interface ProductInformationModalProps {
  className?: string
  product: {
    title: string
    sku: string
    description: ProductDescriptionAccordionProps
  }
  imageGallery: {
    urls: ProductGallery
    i18nText: ImageGalleryProps['i18nText']
  }
  productDescriptionTheme?: ProductAccordionThemeInterface
}

const ProductTitle = styled.h3`
  ${Text('medium1', 'alternate')}
  margin-bottom: ${spacing(4)};

  ${(props) => mq(props.theme.breakpointUtils.map, 'md')} {
    margin-bottom: ${spacing(3)};
  }
`

const ImageGalleryWrapper = styled(GridItem)`
  width: 100%;
  ${(props) => mq(props.theme.breakpointUtils.map, 'md')} {
    padding: ${spacing(2)} ${spacing(2)} 0 ${spacing(2)};
  }

  ${ProductImageThumbnailsWrapper} {
    overflow: initial;
  }

  ${StyledThumbnailList} {
    overflow-y: initial;
  }

  ${CarouselItem} {
    padding: 0%;
  }

  ${StyledImage} {
    ${(props) => mq(props.theme.breakpointUtils.map, 'md')} {
      width: 40px;
      height: 40px;
    }
    ${(props) => mq(props.theme.breakpointUtils.map, 'lg')} {
      width: 50px;
      height: 50px;
    }
  }
`

const ProductInformationWrapper = styled(GridItem)`
  padding: ${spacing(2)};
`

export const ProductInformationModal: React.FunctionComponent<ProductInformationModalProps> = (
  props,
) => {
  return (
    <Grid className={props.className}>
      <ImageGalleryWrapper colSpan={[12, 12, 4, 4]}>
        <ImageGallery
          urls={props.imageGallery.urls}
          altText={props.product.title}
          i18nText={props.imageGallery.i18nText}
          direction={[Direction.COLUMN]}
          gapSpacing={[2]}
          controlSize={[48]}
          thumbnailSize={[74, 74, 40, 50]}
          thumbnailsMarginX={[2, 2, 0, 0]}
          zoomControlPosition={[Position.BOTTOM_RIGHT]}
          fullscreenControlPosition={[Position.TOP_RIGHT]}
        />
      </ImageGalleryWrapper>
      <ProductInformationWrapper
        colStart={[1, 1, 5, 5]}
        colSpan={[12, 12, 8, 8]}
      >
        <ProductTitle>{props.product.title}</ProductTitle>
        <ProductDescriptionAccordion
          {...props.product.description}
          theme={props.productDescriptionTheme}
        />
      </ProductInformationWrapper>
    </Grid>
  )
}
