import * as React from 'react'
import { vsprintf } from 'sprintf-js'

import { i18n, useSiteDefinition } from '@thg-commerce/enterprise-core'
import { pushToDataLayer } from '@thg-commerce/enterprise-metrics/src/data_layer'
import { BasketData } from '@thg-commerce/enterprise-network/src/ApolloProvider/resolvers/Query/Basket/Basket'
import { formatPrice } from '@thg-commerce/enterprise-pricing'
import { Font } from '@thg-commerce/gravity-patterns'
import { ProductAccordionThemeInterface } from '@thg-commerce/gravity-patterns/ProductDescriptionAccordion/types'
import { Accordion, AccordionTitle } from '@thg-commerce/gravity-system'
import { SpacingBox } from '@thg-commerce/gravity-theme'

import { InformationModalPresenter } from './InformationModalPresenter'
import { ProgressBar } from './ProgressBar'
import { SelectYourSampleContext } from './SelectYourSampleContext'
import { ClosedContainer, Container, ContentContainer } from './styles'
import { Qualified, Tier } from './Tier'

export interface SelectYourSampleTheme {
  accordion: {
    title: {
      fontStyle: Font
      padding: SpacingBox
    }
  }
  productDescription: ProductAccordionThemeInterface
}

export type SelectYourSampleProps = NonNullable<
  BasketData['selectYourSample']
>[0] & {
  isOpen: boolean
  selectYourSampleTheme?: SelectYourSampleTheme
  className?: string
}

export const SelectYourSample = (props: SelectYourSampleProps) => {
  const [isOpen, setIsOpen] = React.useState<boolean>(props.isOpen)
  const { defaultLocale, defaultCurrency } = useSiteDefinition()
  const {
    selectYourSampleInteracted,
    setSelectYourSampleInteracted,
  } = React.useContext(SelectYourSampleContext)

  const selectedItemsTotal = props.tiers.reduce((acc, tier) => {
    return acc + tier.selectedProducts.length
  }, 0)

  React.useEffect(() => {
    !selectYourSampleInteracted &&
      selectedItemsTotal > 0 &&
      setSelectYourSampleInteracted(true)
  }, [
    selectYourSampleInteracted,
    selectedItemsTotal,
    setSelectYourSampleInteracted,
  ])

  const trackToggleEvent = (open: boolean) => {
    if (open) {
      pushToDataLayer({
        type: 'elysiumEvent',
        eventData: {
          eventAction: 'Opened Free Gift',
          eventCategory: 'freeProductSelection',
          eventLabel: 'Gift Id',
          eventLabelValue: props.id,
        },
      })
    }

    if (!open) {
      pushToDataLayer({
        type: 'elysiumEvent',
        eventData: {
          eventAction: 'Closed Free Gift',
          eventCategory: 'freeProductSelection',
          eventLabel: 'Gift Id',
          eventLabelValue: props.id,
        },
      })
    }
  }

  const maxSelectedItemsTotal = props.tiers.reduce((acc, tier) => {
    return (
      acc +
      (parseInt(tier.additionalSpendToReach?.amount ?? '0', 10) === 0
        ? tier.maxSelectedProducts
        : 0)
    )
  }, 0)

  const qualifiedWhenClosed = props.tiers.find(
    (tier) => parseInt(tier.additionalSpendToReach?.amount ?? '0', 10) === 0,
  )

  const closestQualifiedAdditionalSpendToReach =
    !qualifiedWhenClosed &&
    Math.min.apply(
      Math,
      props.tiers
        .filter(
          (tier) =>
            parseInt(tier.additionalSpendToReach?.amount ?? '0', 10) !== 0,
        )
        .map((tier) =>
          parseInt(tier.additionalSpendToReach?.amount ?? '0', 10),
        ),
    )

  const i18nText = {
    freeGift: i18n('basket.freegift.text'),
    closedText: i18n('basket.selectyoursample.gifts.selected.text'),
    qualified: i18n('general.qualified.text'),
  }

  return (
    <Container className={props.className}>
      <Accordion
        title={
          <AccordionTitle
            fontStyle={props?.selectYourSampleTheme?.accordion.title.fontStyle}
            padding={props?.selectYourSampleTheme?.accordion.title.padding}
          >
            {props.title || i18nText.freeGift}
          </AccordionTitle>
        }
        id="select-your-sample"
        onClick={() => {
          setIsOpen(!isOpen)
          !selectYourSampleInteracted && setSelectYourSampleInteracted(true)
          trackToggleEvent(!isOpen)
        }}
        isOpen={isOpen}
      >
        <ContentContainer>
          <p>{props.message}</p>
          {props.tiers.map((tier) => {
            return (
              <Tier
                currentAmountSpent={Number(
                  props.currentAmountSpent?.amount ?? 0,
                )}
                id={tier.id}
                products={tier.products}
                isFullyOutOfStock={tier.isFullyOutOfStock}
                isPartiallyOutOfStock={tier.isPartiallyOutOfStock}
                selectedProducts={tier.selectedProducts}
                maxSelectedProducts={tier.maxSelectedProducts}
                key={tier.id}
                thresholdAmountSpent={
                  props.tiers.length > 1 &&
                  tier.thresholdAmountSpent?.displayValue
                }
                additionalSpendToReach={{
                  amount: Number(tier.additionalSpendToReach?.amount || '0'),
                  displayValue:
                    tier.additionalSpendToReach?.displayValue || '0',
                }}
              />
            )
          })}
        </ContentContainer>
      </Accordion>
      <ClosedContainer
        qualifiedWhenClosed={typeof qualifiedWhenClosed !== 'undefined'}
        isOpen={isOpen}
        data-testid="accordion-closed"
      >
        {qualifiedWhenClosed ? (
          <React.Fragment>
            <Qualified text={i18nText.qualified} />
            <p>
              {vsprintf(i18nText.closedText, [
                selectedItemsTotal,
                maxSelectedItemsTotal,
              ])}
            </p>
          </React.Fragment>
        ) : (
          <ProgressBar
            additionalSpendToReach={{
              amount: closestQualifiedAdditionalSpendToReach || 0,
              displayValue: props.currentAmountSpent
                ? formatPrice(
                    defaultCurrency,
                    parseInt(props.currentAmountSpent.amount, 10) +
                      (closestQualifiedAdditionalSpendToReach || 0),
                    defaultLocale,
                  )
                : '',
            }}
            currentAmountSpent={
              props.currentAmountSpent
                ? parseInt(props.currentAmountSpent.amount, 10)
                : 0
            }
            isOpen={isOpen}
          />
        )}
      </ClosedContainer>
      <InformationModalPresenter
        productDescriptionTheme={
          props.selectYourSampleTheme?.productDescription
        }
      />
    </Container>
  )
}
