import * as React from 'react'

import { GenericEmbedProps } from './types'
import { EmbedWrapper } from './style'

export interface SpotifyEmbedProps extends GenericEmbedProps {
  title?: string
}

export const Spotify = (props: SpotifyEmbedProps) => {
  if (props.isAmp) return null

  let type = ''
  // This is copied from the code that powers this page https://developer.spotify.com/documentation/widgets/generate/embed/ (https://developer.spotify.com/js/widgets.bundle.js)
  // So that we can generate the correct iframe url
  const parseUrl = props.url.match(
    /^https?:\/\/(?:open|play)\.spotify\.com\/(?:track|album|artist|show|episode|playlist)\/[a-zA-Z0-9]{22}\/?$/,
  )
    ? `spotify:${props.url
        .replace(/^https?:\/\/(?:open|play)\.spotify\.com\//, '')
        .split('/')
        .join(':')}`
    : (!!props.url.match(
        /^https?:\/\/(?:open|play)\.spotify\.com\/user\/[^:]+\/playlist\/[a-zA-Z0-9]{22}\/?$/,
      ) &&
        `spotify:${props.url
          .replace(/^https?:\/\/(?:open|play)\.spotify\.com\//, '')
          .split('/')
          .join(':')}`) ||
      ''
  if (!parseUrl) return null

  const matchSpotifyUri = parseUrl.match(
    /^spotify:(track|album|user|artist|show|episode|playlist):[^:]+(?::(playlist):[A-Za-z0-9]+)?/,
  )
  const isPlaylist =
    matchSpotifyUri && !matchSpotifyUri[2] && matchSpotifyUri[1] === 'playlist'

  if (matchSpotifyUri) {
    if (isPlaylist) {
      type = 'playlist'
    } else if (!matchSpotifyUri[2]) {
      switch (matchSpotifyUri[1]) {
        case 'track':
        case 'album':
        case 'user':
        case 'show':
        case 'episode':
          type = matchSpotifyUri[1]
          break
      }
    }
  }

  const embedPodcastDomain = 'https://open.spotify.com/embed-podcast'
  const domain = 'https://open.spotify.com/embed'
  const isPodcast = type === 'episode' || type === 'show'
  const iframeSrc = `${
    isPodcast ? embedPodcastDomain : domain
  }/${parseUrl.replace('spotify:', '').split(':').join('/')}`

  return (
    <EmbedWrapper>
      <iframe
        title={props.title || 'Spotify Embed'}
        src={iframeSrc}
        width={props.width || isPodcast ? '100%' : 300}
        height={props.height || isPodcast ? 232 : 380}
        frameBorder="0"
        allow="encrypted-media"
      ></iframe>
    </EmbedWrapper>
  )
}
