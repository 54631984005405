import * as React from 'react'

import { Flex } from '@thg-commerce/gravity-system'
import {
  Margin,
  margin,
  spacing,
  styled,
  Text,
  ThemeInterface,
} from '@thg-commerce/gravity-theme'

interface SeparatorProps {
  theme?: ThemeInterface
  className?: string
  withSpacing?: boolean
  maxWidth?: number
  separatorMargin?: Margin
}

const StyledSeparator = styled(Flex)<SeparatorProps>`
  flex-basis: 100%;
  align-items: center;
  ${(props) => props.maxWidth && `max-width: ${props.maxWidth}px;`}
  ${(props) => props.separatorMargin && margin(props.separatorMargin)}

  color: ${(props) => props.theme.colors.palette.greys.dark};
  ${(props) =>
    Text(props.theme.separator.font.entry, props.theme.separator.font.style)}

  ${(props) =>
    props.withSpacing &&
    (props.separatorMargin?.top && props.separatorMargin?.bottom
      ? `margin-top: ${props.separatorMargin?.top} px; margin-bottom: ${props.separatorMargin?.bottom}px;`
      : `margin-top: ${spacing(2.5)}; margin-bottom: ${spacing(2.5)};`)}
    
  &::before {
    content: '';
    flex-grow: 1;
    background: ${(props) => props.theme.colors.palette.greys.light};
    height: 1px;
    font-size: 0;
    line-height: 0;
    ${(props) => (props.children ? 'margin-right: 15px' : '')};
  }
  &::after {
    content: '';
    flex-grow: 1;
    background: ${(props) => props.theme.colors.palette.greys.light};
    height: 1px;
    font-size: 0;
    line-height: 0;
    ${(props) => (props.children ? 'margin-left: 15px' : '')}
  }
`

/** @component */
export const Separator = (props: SeparatorProps) => (
  <StyledSeparator {...props} />
)

export default Separator
