import { styled, spacing, mq, Text } from '@thg-commerce/gravity-theme'

const columnWidthMap = {
  name: '1fr',
  price: '108px',
  quantity: '144px',
  subtotal: '100px',
  delete: '48px',
}

export const Container = styled.div``

export const Heading = styled.div<{ components?: string[] }>`
  display: none;

  ${Text('bodyText', 'alternate')};

  ${(props) => mq(props.theme.breakpointUtils.map, 'md')} {
    display: block;
    display: grid;
    display: -ms-grid;
    grid-column-gap: ${spacing(4)};
    grid-row-gap: ${spacing(4)};
    grid-template-columns: ${(props) =>
      props.components
        ? props.components.map((component) => `${columnWidthMap[component]} `)
        : '1fr 108px 144px 100px 48px'};
    -ms-grid-columns: ${(props) =>
      props.components
        ? props.components.map((component) => `${columnWidthMap[component]} `)
        : '1fr 108px 144px 100px 48px'};
    grid-template-areas: '${(props) =>
      props.components
        ? props.components?.join(' ')
        : 'name price quantity subtotal delete'}';
  }
`

export const TextContainer = styled.div`
  display: grid;
  display: -ms-grid;
  grid-column-gap: ${spacing(2)};
  grid-row-gap: ${spacing(2)};
  grid-template-columns: auto 1fr auto;
  -ms-grid-columns: auto 1fr auto;
`

export const Subtitle = styled.div`
  ${Text('small', 'alternate')};
  display: flex;
  align-items: center;
  margin-bottom: ${spacing(1.5)};

  svg {
    width: 24px;
    height: 24px;
    margin-left: ${spacing(1)};
    margin-right: ${spacing(1)};
  }
`

export const Price = styled.span<{ hide?: boolean }>`
  grid-area: price;
  width: 100px;
  display: none;

  ${(props) => mq(props.theme.breakpointUtils.map, 'md')} {
    display: ${(props) => (props.hide ? 'none' : 'block')};
    margin: auto ${spacing(3)} auto 0;
  }
`

export const Quantity = styled.span`
  grid-area: quantity;
  width: 130px;
  margin: auto ${spacing(2)} auto 0;

  ${(props) => mq(props.theme.breakpointUtils.map, 'md')} {
    width: 144px;
    margin: auto ${spacing(6)} auto 0;
  }
`

export const Subtotal = styled.span`
  grid-area: subtotal;
  width: 70px;
  margin: auto ${spacing(2)} auto 0;

  ${(props) => mq(props.theme.breakpointUtils.map, 'md')} {
    width: 100px;
    margin: auto ${spacing(2)} auto 0;
  }
`

export const ClosePlaceholder = styled.span`
  grid-area: delete;
  width: 48px;
`

export const StyledLi = styled.li`
  margin-top: ${spacing(2)};

  ${(props) => mq(props.theme.breakpointUtils.map, 'sm')} {
    margin: 0px;
  }
`

export const Separator = styled.hr<{ topMargin: number; bottomMargin: number }>`
  border: 0;
  border-top: 1px solid ${(props) => props.theme.colors.palette.greys.light};
  margin-top: ${(props) => `${spacing(props.topMargin)}`};

  ${(props) => mq(props.theme.breakpointUtils.map, 'sm')} {
    margin: ${(props) =>
      `${spacing(props.topMargin)} 0 ${spacing(props.bottomMargin)} 0`};
  }
`
