import * as React from 'react'

import {
  BasketContext,
  i18n,
  useLoginCheck,
} from '@thg-commerce/enterprise-core'
import { PlatformMessage } from '@thg-commerce/gravity-elements'
import { ApplyCodeToBasketState } from '@thg-commerce/enterprise-core/src/ConfigurationLoader/types'

export const Referrals = (props: { className?: string }) => {
  const { applyCodeToBasketState } = React.useContext(BasketContext)
  const isLoggedIn = useLoginCheck()

  const i18nText = {
    NOT_LOGGED_IN: i18n('basket.referrals.unauthenticated'),
    REFERRAL_CODE_INVALID: i18n('basket.referrals.code.invalid'),
    REFERRER_NOT_ELIGIBLE: i18n('basket.referrals.code.referrernoteligible'),
    REFERRAL_CODE_APPLIED: i18n('basket.referrals.code.applied'),
  }

  const isReferralCodeMessage =
    applyCodeToBasketState &&
    (applyCodeToBasketState === ApplyCodeToBasketState.REFERRAL_CODE_APPLIED ||
      applyCodeToBasketState === ApplyCodeToBasketState.REFERRAL_CODE_INVALID ||
      applyCodeToBasketState === ApplyCodeToBasketState.NOT_LOGGED_IN ||
      applyCodeToBasketState === ApplyCodeToBasketState.REFERRER_NOT_ELIGIBLE)

  if (
    applyCodeToBasketState === ApplyCodeToBasketState.REFERRER_NOT_ELIGIBLE &&
    !isLoggedIn
  ) {
    return null
  }

  const isError =
    applyCodeToBasketState === ApplyCodeToBasketState.REFERRAL_CODE_INVALID ||
    applyCodeToBasketState === ApplyCodeToBasketState.REFERRER_NOT_ELIGIBLE

  return isReferralCodeMessage ? (
    <PlatformMessage
      type={isError ? 'error' : 'success'}
      text={applyCodeToBasketState && i18nText[applyCodeToBasketState]}
      data-testid="referral-message"
      className={props.className}
    />
  ) : null
}
