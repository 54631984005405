export const SAFEHTML_ALLOWED_TAGS = [
  'table, caption, colgroup, col, th, thead, tbody, tr, td, tfoot',
  'strong',
  'em',
  'b',
  'span',
  'img',
  'small',
]

export const SAFEHTML_ALLOWED_ATTRIBUTES = {
  tbody: ['tabindex'],
  td: ['colspan'],
  span: ['style'],
  img: ['alt', 'src', 'style'],
}
