import * as React from 'react'

import { i18n } from '@thg-commerce/enterprise-core'
import { BasketData } from '@thg-commerce/enterprise-network/src/ApolloProvider/resolvers/Query/Basket/Basket'
import { useTheme } from '@thg-commerce/enterprise-pages/src/Basket/theme'

import { BasketItemSeparatorMargin } from '../theme'

import { Item } from './Item'
import {
  ClosePlaceholder,
  Container,
  Heading,
  Price,
  Quantity,
  Separator,
  StyledLi,
  Subtotal,
} from './styles'

export const Table = React.memo(
  (props: {
    basket: BasketData
    className?: string
    basketItemSeparatorMargin?: BasketItemSeparatorMargin
  }) => {
    const theme = useTheme()

    const i18nText = {
      item: i18n('general.item.text'),
      items: i18n('general.items.text'),
      price: i18n('general.price.text'),
      quantity: i18n('general.quantity.text'),
      itemSubtotal: i18n('general.subtotal.text'),
      basketSubtotal: i18n('basket.subtotal.text'),
    }

    const basketItems = React.useMemo(
      () =>
        props.basket.items.map((item, index) => {
          const discountMessages =
            item.appliedOffers &&
            item.appliedOffers.map((offer) => offer.message)

          return (
            <StyledLi key={item.id}>
              <Item
                {...item}
                discountMessages={discountMessages}
                index={index}
                data-testid="basket-item"
              />
              <Separator
                topMargin={props?.basketItemSeparatorMargin?.margin.top || 3}
                bottomMargin={
                  props?.basketItemSeparatorMargin?.margin.bottom || 3
                }
              />
            </StyledLi>
          )
        }),
      [
        props.basket.items,
        props?.basketItemSeparatorMargin?.margin.bottom,
        props?.basketItemSeparatorMargin?.margin.top,
      ],
    )

    const tableComponents = theme?.pageTheme?.table?.components?.sm?.order

    return (
      <Container className={props.className}>
        <Heading
          id="basket-items-table"
          components={theme?.pageTheme?.table?.headerComponents}
        >
          <span>
            {props.basket.items.length === 1 ? i18nText.item : i18nText.items}
          </span>
          {(tableComponents?.includes('price') ?? true) && (
            <Price data-testid="initial-price-heading">{i18nText.price}</Price>
          )}
          {(tableComponents?.includes('quantity') ?? true) && (
            <Quantity>{i18nText.quantity}</Quantity>
          )}
          {(tableComponents?.includes('subtotal') ?? true) && (
            <Subtotal>{i18nText.itemSubtotal}</Subtotal>
          )}

          <ClosePlaceholder />
        </Heading>
        <Separator topMargin={2} bottomMargin={3} />
        <ul aria-labelledby="basket-items-table">{basketItems}</ul>
      </Container>
    )
  },
)
