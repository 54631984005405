import * as React from 'react'

import { i18n } from '@thg-commerce/enterprise-core'
import { PageMessage } from '@thg-commerce/gravity-patterns'
import { CheckoutStartError } from '@thg-commerce/enterprise-network/src/generated/graphql'

export type CheckoutErrorType = CheckoutStartError & { GENERIC?: string }

export interface CheckoutErrorProps {
  error: CheckoutErrorType
  className?: string
}

export const CheckoutError = (props: CheckoutErrorProps) => {
  const checkoutErrorI18nText = {
    BASKETS_MERGED: i18n('basket.checkoutstart.error.basketsmerged.text'),
    GENERIC: i18n('basket.checkoutstart.error.generic.text'),
  }

  return (
    <PageMessage
      type="error"
      message={
        checkoutErrorI18nText[props.error] || checkoutErrorI18nText.GENERIC
      }
      flashMessageId="checkout-start-error"
      className={props.className}
    />
  )
}
