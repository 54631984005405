import { styled, spacing } from '@thg-commerce/gravity-theme'

export const EmbedWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding: ${spacing(1)} 0;

  iframe,
  & > div {
    display: block;
  }
`

export const AmpEmbedWrapper = styled.div`
  max-width: 326px;
  max-height: 620px;
  margin: 0 auto;
`
