import { mq, spacing, styled, Text } from '@thg-commerce/gravity-theme'
import { DrawFocus, SafeHtml } from '@thg-commerce/gravity-elements'

export const Title = styled.h3`
  ${Text('bodyText', 'alternate')}

  margin-bottom: ${spacing(1)};
`

export const RemoveOffer = styled.button`
  ${DrawFocus()}

  cursor: pointer;
  height: 40px;
  margin: -${spacing(1)} 0;
  text-decoration: underline;
`

export const OfferValue = styled.p`
  ${Text('bodyText', 'alternate')};
  color: ${(props) => props.theme.colors.success.base};
  margin-left: ${spacing(2)};

  ${(props) => mq(props.theme.breakpointUtils.map, 'md')} {
    margin-left: ${spacing(5)};
  }
`

export const OfferWrapper = styled.div`
  display: flex;
  margin-bottom: ${spacing(1)};

  &:last-child {
    margin-bottom: 0;
  }
`

export const OfferMessage = styled(SafeHtml)`
  ${Text('bodyText', 'alternate')};
  color: ${(props) => props.theme.colors.success.base};
  flex: 1;
  background: none;
  padding-left: 0px;
  padding-right: 0px;

  &:last-child {
    padding-bottom: 0px;
  }
`

export const RemoveOfferWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`
