import * as React from 'react'
import Head from 'next/head'

import { EmbedWrapper } from './style'
import { GenericEmbedProps } from './types'

export const Twitter = (props: GenericEmbedProps) => {
  return (
    <React.Fragment>
      <Head>
        <script
          key="twitter-embed"
          id="twitter-embed"
          async
          src="https://platform.twitter.com/widgets.js"
        ></script>
      </Head>
      <EmbedWrapper>
        <blockquote
          // className required for widget.js
          className="twitter-tweet"
          style={{ overflow: 'hidden', maxWidth: '100%' }}
        >
          <a href={props.url}>{props.url}</a>
        </blockquote>
      </EmbedWrapper>
    </React.Fragment>
  )
}
