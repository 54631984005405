import * as React from 'react'

import withHydrationOnDemand from 'react-hydration-on-demand'

import { Accordion } from '@thg-commerce/gravity-system'
import { AccordionBorderPlacement } from '@thg-commerce/gravity-system/components/Accordion'
import { Separator, SafeHtml } from '@thg-commerce/gravity-elements'
import { useTheme } from '@thg-commerce/gravity-patterns/theme'

import {
  ProductContentSafeHTML as ProductContentSafeHTMLComponent,
  ProductPromotionalMessage,
} from './styles'
import { Details as DetailsComponent } from './Details'
import { ProductDescriptionAccordionProps } from './types'
import { SAFEHTML_ALLOWED_TAGS } from './config'

const Details = withHydrationOnDemand({ on: ['visible'] })(DetailsComponent)
const ProductContentSafeHTML = withHydrationOnDemand({ on: ['visible'] })(
  ProductContentSafeHTMLComponent,
)

export const ProductDescriptionAccordion: React.FunctionComponent<ProductDescriptionAccordionProps> = (
  props,
) => {
  const theme = useTheme()
  const detailsIndex = props.details ? 1 : 0
  const productPromotionalMessage = props.items.find(
    (item) => item.key === 'productMessage',
  )
  return (
    <React.Fragment>
      {props.customTopItems &&
        props.customTopItems.map((customTopItem, index) => {
          return (
            <React.Fragment key={customTopItem.key}>
              <Accordion
                borderPlacement={AccordionBorderPlacement.TOP}
                title={customTopItem.title}
                id={customTopItem.key}
                isOpen={
                  index <
                  theme.patterns.productDescriptionAccordion.defaultItemsOpen
                }
                theme={props.theme?.accordion}
                onOpenAccordion={props.onOpenAccordion}
              >
                {customTopItem.content}
              </Accordion>
            </React.Fragment>
          )
        })}
      {props.items &&
        props.items.map((item, index) => {
          if (item.key === 'productMessage') {
            return null
          }
          return (
            <React.Fragment key={item.key}>
              <Accordion
                borderPlacement={AccordionBorderPlacement.TOP}
                title={item.title}
                id={item.key}
                isOpen={
                  (props.customTopItems?.length || 0) + index <
                  theme.patterns.productDescriptionAccordion.defaultItemsOpen
                }
                theme={props.theme?.accordion}
                onOpenAccordion={props.onOpenAccordion}
              >
                {props.enablePromoMessageOnProductDescription &&
                  productPromotionalMessage?.content && (
                    <ProductPromotionalMessage>
                      <SafeHtml content={productPromotionalMessage?.content} />
                    </ProductPromotionalMessage>
                  )}
                <ProductContentSafeHTML
                  removeTagsAndContent={false}
                  __dangerouslyAllowedTags={SAFEHTML_ALLOWED_TAGS}
                  content={item.content}
                />
              </Accordion>
            </React.Fragment>
          )
        })}
      {props.details?.display && (
        <React.Fragment>
          <Accordion
            borderPlacement={AccordionBorderPlacement.TOP}
            title={props.details.title}
            id="productDetails"
            isOpen={
              (props.customTopItems?.length || 0) +
                props.items.length +
                detailsIndex <=
              theme.patterns.productDescriptionAccordion.defaultItemsOpen
            }
            theme={props.theme?.accordion}
            onOpenAccordion={props.onOpenAccordion}
          >
            <Details details={props.details} />
          </Accordion>
        </React.Fragment>
      )}
      {props.customBottomItems &&
        props.customBottomItems.map((customBottomItem) => {
          return (
            <React.Fragment>
              <Accordion
                borderPlacement={AccordionBorderPlacement.TOP}
                title={customBottomItem.title}
                id={customBottomItem.key}
                isOpen={
                  (props.customTopItems?.length || 0) +
                    props.items.length +
                    detailsIndex <
                  theme.patterns.productDescriptionAccordion.defaultItemsOpen
                }
                theme={props.theme?.accordion}
                onOpenAccordion={props.onOpenAccordion}
              >
                {customBottomItem.content}
              </Accordion>
            </React.Fragment>
          )
        })}
      <Separator aria-hidden={true} />
    </React.Fragment>
  )
}
