import * as React from 'react'
import Head from 'next/head'

import { useSiteConfig } from '@thg-commerce/enterprise-core'

export const PowerReviewHead = () => {
  const { powerReviewApiUrl } = useSiteConfig()
  return (
    <Head>
      <script
        type="text/javascript"
        crossOrigin="anonymous"
        src={powerReviewApiUrl}
        defer
      />
    </Head>
  )
}

export default PowerReviewHead
