import * as React from 'react'

import dynamic from 'next/dynamic'

import { spacing, styled, Text, mq } from '@thg-commerce/gravity-theme'
import { Button } from '@thg-commerce/gravity-elements'

export const Basket = dynamic(
  () => import('@thg-commerce/gravity-icons/src/components/BasketIcon'),
  { loading: () => <BasketIconSkeleton />, ssr: true },
)

export const Container = styled.div`
  background-color: ${(props) => props.theme.colors.palette.greys.white};
  padding: ${spacing(1)} ${spacing(2)} ${spacing(1)} ${spacing(2)};

  ${(props) => mq(props.theme.breakpointUtils.map, 'md')} {
    padding: ${spacing(4)} 0;
  }
`

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: ${spacing(7)};

  ${(props) => mq(props.theme.breakpointUtils.map, 'md')} {
    margin-bottom: ${spacing(8)};
  }
`

export const Title = styled.p`
  ${Text('large1', 'alternate')}
  margin: ${spacing(2)} auto ${spacing(1)} auto;
  text-align: center;
`

export const Subtitle = styled.p`
  margin: 0 auto ${spacing(2)} auto;
  text-align: center;
`

export const BasketIcon = styled(Basket)`
  width: 64px;
  height: 64px;
  margin: 0 auto;
`

export const BasketIconSkeleton = styled.div`
  width: 64px;
  height: 64px;
  margin: 0 auto;
`

export const ContinueShoppingLink = styled(Button)`
  margin: 0 auto;
`
