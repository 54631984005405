import { spacing, styled } from '@thg-commerce/gravity-theme'

export const Container = styled.div`
  background-color: ${(props) => props.theme.colors.palette.greys.lighter};
`

export const ClosedContainer = styled.div<{
  isOpen: boolean
  qualifiedWhenClosed: boolean
}>`
  padding: ${spacing(1)} ${spacing(2)};

  ${(props) =>
    props.qualifiedWhenClosed &&
    `
    display: flex;
    justify-content: space-between;
    `};

  ${(props) => props.isOpen && 'display: none;'};
`

export const ContentContainer = styled.div`
  padding: 0 ${spacing(2)} ${spacing(2)};
`
