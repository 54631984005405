import * as React from 'react'
import Head from 'next/head'

import { EmbedWrapper } from './style'
import { GenericEmbedProps } from './types'

export const Pinterest = (props: GenericEmbedProps) => {
  return (
    <React.Fragment>
      <Head>
        <script
          src="//assets.pinterest.com/js/pinit.js"
          key="interest-embed"
          id="pinterest-embed"
          async
          defer
        ></script>
      </Head>
      <EmbedWrapper>
        <a
          href={props.url}
          data-pin-do="embedPin"
          style={{ overflow: 'hidden', maxWidth: '100%' }}
        >
          {props.url}
        </a>
      </EmbedWrapper>
    </React.Fragment>
  )
}
