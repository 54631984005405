import * as React from 'react'

import { Items } from './Items'
import { Summary } from './Summary'
import { Heading } from './Heading'

export const Table = () => {
  return (
    <React.Fragment>
      <Heading />
      <Items />
      <Summary />
    </React.Fragment>
  )
}
