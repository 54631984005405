import * as React from 'react'

import { styled, mq } from '@thg-commerce/enterprise-theme'
import { NewSkeleton as Skeleton } from '@thg-commerce/gravity-elements'

const MobileContainer = styled(Skeleton)`
  height: 75px;

  ${(props) => mq(props.theme.breakpointUtils.map, 'sm')} {
    display: none;
  }
`
const TabletContainer = styled(Skeleton)`
  display: none;

  ${(props) => mq(props.theme.breakpointUtils.map, 'sm')} {
    display: block;
    height: 75px;
  }

  ${(props) => mq(props.theme.breakpointUtils.map, 'md')} {
    display: none;
  }
`
const DesktopContainer = styled(Skeleton)`
  display: none;

  ${(props) => mq(props.theme.breakpointUtils.map, 'md')} {
    display: block;
    height: 75px;
  }
`

const MobileSummary = () => {
  return (
    <MobileContainer>
      <rect x="0" y="24" rx="3" ry="3" width="172" height="18" />
      <rect
        x="0"
        y="24"
        rx="3"
        ry="3"
        width="86"
        height="18"
        style={{ transform: 'translate(calc(100% - 86px), 0)' }}
      />
      <rect x="0" y="66" rx="3" ry="3" width="100%" height="3" />
    </MobileContainer>
  )
}

const TabletSummary = () => {
  return (
    <TabletContainer>
      <rect
        x="0"
        y="24"
        rx="3"
        ry="3"
        width="144"
        height="18"
        style={{ transform: 'translate(calc(100% - 311px), 0)' }}
      />
      <rect
        x="0"
        y="24"
        rx="3"
        ry="3"
        width="60"
        height="18"
        style={{ transform: 'translate(calc(100% - 151px), 0)' }}
      />
      <rect x="0" y="66" rx="3" ry="3" width="100%" height="3" />
    </TabletContainer>
  )
}

const DesktopSummary = () => {
  return (
    <DesktopContainer>
      <rect
        x="0"
        y="24"
        rx="3"
        ry="3"
        width="164"
        height="18"
        style={{ transform: 'translate(calc(100% - 330px), 0)' }}
      />
      <rect
        x="0"
        y="24"
        rx="3"
        ry="3"
        width="60"
        height="18"
        style={{ transform: 'translate(calc(100% - 150px), 0)' }}
      />
      <rect x="0" y="66" rx="3" ry="3" width="100%" height="3" />
    </DesktopContainer>
  )
}

export const Summary = () => {
  return (
    <React.Fragment>
      <MobileSummary />
      <TabletSummary />
      <DesktopSummary />
    </React.Fragment>
  )
}
