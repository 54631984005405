import * as React from 'react'

import { styled, mq, spacing } from '@thg-commerce/enterprise-theme'
import { NewSkeleton as Skeleton } from '@thg-commerce/gravity-elements'

const MobileContainer = styled(Skeleton)`
  height: 280px;
  margin-top: ${spacing(4)};

  ${(props) => mq(props.theme.breakpointUtils.map, 'sm')} {
    display: none;
  }
`

const TabletContainer = styled(Skeleton)`
  display: none;

  ${(props) => mq(props.theme.breakpointUtils.map, 'sm')} {
    display: block;
    height: 120px;
    margin-top: ${spacing(4)};
  }
`

const MobileCheckout = () => {
  return (
    <MobileContainer>
      <rect x="0" y="0" rx="3" ry="3" width="100%" height="48" />
      <rect x="60" y="80" rx="3" ry="3" width="60" height="40" />
      <rect x="128" y="80" rx="3" ry="3" width="60" height="40" />
      <rect x="196" y="80" rx="3" ry="3" width="60" height="40" />
      <rect x="264" y="80" rx="3" ry="3" width="60" height="40" />
      <rect x="332" y="80" rx="3" ry="3" width="60" height="40" />
      <rect x="158" y="128" rx="3" ry="3" width="60" height="40" />
      <rect x="226" y="128" rx="3" ry="3" width="60" height="40" />
      <rect x="0" y="192" rx="3" ry="3" width="100%" height="48" />
    </MobileContainer>
  )
}

const TabletCheckout = () => {
  return (
    <TabletContainer>
      <rect x="0" y="0" rx="3" ry="3" width="210" height="48" />
      <rect
        x="0"
        y="0"
        rx="3"
        ry="3"
        width="277"
        height="48"
        style={{ transform: 'translate(calc(100% - 277px), 0)' }}
      />
      <rect
        x="0"
        y="80"
        rx="3"
        ry="3"
        width="60"
        height="40"
        style={{ transform: 'translate(calc(100% - 60px), 0)' }}
      />

      <rect
        x="0"
        y="80"
        rx="3"
        ry="3"
        width="60"
        height="40"
        style={{ transform: 'translate(calc(100% - 128px), 0)' }}
      />

      <rect
        x="0"
        y="80"
        rx="3"
        ry="3"
        width="60"
        height="40"
        style={{ transform: 'translate(calc(100% - 196px), 0)' }}
      />

      <rect
        x="0"
        y="80"
        rx="3"
        ry="3"
        width="60"
        height="40"
        style={{ transform: 'translate(calc(100% - 264px), 0)' }}
      />

      <rect
        x="0"
        y="80"
        rx="3"
        ry="3"
        width="60"
        height="40"
        style={{ transform: 'translate(calc(100% - 332px), 0)' }}
      />
      <rect
        x="0"
        y="80"
        rx="3"
        ry="3"
        width="60"
        height="40"
        style={{ transform: 'translate(calc(100% - 400px), 0)' }}
      />
      <rect
        x="0"
        y="80"
        rx="3"
        ry="3"
        width="60"
        height="40"
        style={{ transform: 'translate(calc(100% - 468px), 0)' }}
      />
    </TabletContainer>
  )
}

export const Checkout = () => {
  return (
    <React.Fragment>
      <MobileCheckout />
      <TabletCheckout />
    </React.Fragment>
  )
}
