import * as React from 'react'
import Head from 'next/head'

import { useSiteConfig } from '@thg-commerce/enterprise-core'
import { spacing, styled } from '@thg-commerce/gravity-theme'

export const ProvenanceBundleWrapper = styled.div`
  padding: ${spacing(1)};
`

type ProvenanceBundleProps = {
  productId: number
}

export const ProvenanceBundle = ({ productId }: ProvenanceBundleProps) => {
  const {
    provenanceSdkUrl,
    provenanceSdkIntegrityHash,
    provenanceProductBundleUrl,
  } = useSiteConfig()
  if (
    !provenanceSdkUrl ||
    !provenanceSdkIntegrityHash ||
    !provenanceProductBundleUrl
  ) {
    return null
  }

  const provenanceBundle = React.createElement('provenance-bundle', {
    url: provenanceProductBundleUrl,
    schema: 'Product',
  })

  return (
    <ProvenanceBundleWrapper>
      <Head>
        <script
          type="text/javascript"
          crossOrigin="anonymous"
          src={provenanceSdkUrl}
          integrity={provenanceSdkIntegrityHash}
          defer
        />
      </Head>
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{
          __html: JSON.stringify({
            '@type': 'Product',
            '@context': 'https://schema.org',
            productID: productId,
          }),
        }}
      />
      {provenanceBundle}
    </ProvenanceBundleWrapper>
  )
}
