import * as React from 'react'

import { styled, spacing, mq } from '@thg-commerce/enterprise-theme'
import { useCheckoutStart } from '@thg-commerce/enterprise-core'
import { PaymentIcon } from '@thg-commerce/enterprise-components'
import {
  PaymentIcon as PaymentIconType,
  PaymentType,
  SubPaymentType,
} from '@thg-commerce/enterprise-config'

const Container = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;

  ${(props) => mq(props.theme.breakpointUtils.map, 'sm')} {
    justify-content: flex-end;
  }
`

const StyledPaymentIcon = styled(PaymentIcon)`
  margin: 0 0 ${spacing(1)} ${spacing(1)};

  &:first-child {
    margin-left: 0;
  }
`

interface PaymentIconsProps {
  className?: string
  paymentMethods: {
    icon: PaymentIconType
    type: PaymentType
    subType: SubPaymentType | null
  }[]
}

export const PaymentIcons: React.FunctionComponent<PaymentIconsProps> = (
  props,
) => {
  const { execute: executeCheckoutStart } = useCheckoutStart()

  if (props.paymentMethods.length === 0) {
    return null
  }

  return (
    <Container className={props.className}>
      {props.paymentMethods.map((paymentMethod, index) => {
        return (
          <StyledPaymentIcon
            onClick={() =>
              executeCheckoutStart(paymentMethod.type, paymentMethod.subType)
            }
            key={`paymentMethod_${paymentMethod.type}_${index}`}
            data-testid={`basket-paymenticon-${paymentMethod.type}_${index}`}
            icon={paymentMethod.icon}
            includeBorder={true}
          />
        )
      })}
    </Container>
  )
}
