import * as React from 'react'

import { Routes } from '@thg-commerce/enterprise-core'
import { Widget } from '@thg-commerce/enterprise-network/src/generated/graphql'

import {
  Container,
  BasketIcon,
  Title,
  Subtitle,
  ContinueShoppingLink,
  ContentWrapper,
} from './styles'
import { WidgetRenderer } from '@thg-commerce/enterprise-widgets'

interface BasketEmptyProps {
  i18nText: {
    title: string
    subtitle: string
    continueShopping: string
  }
  widgets?: Omit<Widget, 'query'>[]
}

export const Empty = ({ i18nText, widgets }: BasketEmptyProps) => {
  return (
    <Container>
      <ContentWrapper>
        <BasketIcon />
        <Title>{i18nText.title}</Title>
        <Subtitle>{i18nText.subtitle}</Subtitle>
        <ContinueShoppingLink
          href={Routes.HomePage}
          ariaLabel={i18nText.continueShopping}
          renderedAs="a"
        >
          {i18nText.continueShopping}
        </ContinueShoppingLink>
      </ContentWrapper>
      <WidgetRenderer widgets={widgets || []} path={Routes.Basket} />
    </Container>
  )
}
