import fs from 'fs'
import Keyv from 'keyv'
import getConfig from 'next/config'
import path from 'path'
import { ThemedStyledComponentsModule } from 'styled-components'

import { NextConfig } from '@thg-commerce/enterprise-config/types'
import {
  EnterpriseThemeInterface,
  styled,
  useTheme,
} from '@thg-commerce/enterprise-theme'

import { Component, PageLayout, PageTheme } from './Renderer/layout'

const nextConfig = getConfig() as NextConfig

export const pageLayoutStorage = Object.freeze(
  new Keyv({
    namespace: 'pageLayout',
    serialize: JSON.stringify,
    deserialize: JSON.parse,
  }),
)

const loadPageLayoutFile = <
  PageLayoutType extends PageLayout<Component<any>>
>(config: {
  page: string
  brand: string
  subsite: string
  nextConfig?: NextConfig
}): (PageLayoutType & Pick<PageTheme<PageLayoutType>, 'theme'>) | null => {
  const pageLayoutPath = path.posix.join(
    config.nextConfig?.serverRuntimeConfig.CONFIGURATION_PATH ||
      nextConfig?.serverRuntimeConfig.CONFIGURATION_PATH,
    `${config.brand}`,
    `${config.subsite}`,
    `page_theme_${config.page}.json`,
  )
  if (fs.existsSync(pageLayoutPath)) {
    const pageLayout = JSON.parse(
      fs.readFileSync(pageLayoutPath, 'utf8'),
    ) as PageLayoutType

    return {
      ...pageLayout,
      theme: pageLayout.components.reduce<PageTheme<PageLayoutType>['theme']>(
        (accumulator, component) => {
          accumulator[component.type] = component
          return accumulator
        },
        {} as any,
      ),
    }
  }
  return null
}

export const loadPageTheme = async <
  PageLayoutType extends PageLayout<Component<any>>
>(config: {
  page: string
  brand: string
  subsite: string
  layoutName?: string
  nextConfig?: NextConfig
}): Promise<PageTheme<PageLayoutType> | null> => {
  const key = [config.brand, config.subsite, config.page].join('_')
  let cachedPageLayout:
    | (PageLayoutType & Pick<PageTheme<PageLayoutType>, 'theme'>)
    | null = await pageLayoutStorage.get(key)

  if (typeof cachedPageLayout === 'undefined') {
    cachedPageLayout = loadPageLayoutFile<PageLayoutType>(config)

    if (cachedPageLayout) {
      pageLayoutStorage.set(
        key,
        cachedPageLayout,
        300000, // 5 minutes
      )
    }
  }

  if (!cachedPageLayout) {
    return null
  }

  const pageTheme = {
    layout:
      cachedPageLayout.layout[config.layoutName || ''] ||
      cachedPageLayout.layout.default,
    components: cachedPageLayout.components,
    theme: cachedPageLayout.theme,
  }

  return pageTheme
}

export const createPageLayoutTheme = <
  PageLayoutType extends PageLayout<Component<any>>
>() => {
  const theme = ({
    useTheme,
    default: styled,
  } as unknown) as ThemedStyledComponentsModule<
    {
      pageTheme: PageTheme<PageLayoutType>['theme']
    } & EnterpriseThemeInterface
  >

  return {
    styled: theme.default,
    useTheme: theme.useTheme,
  }
}
