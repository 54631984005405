import * as React from 'react'

import { styled, mq } from '@thg-commerce/enterprise-theme'
import { NewSkeleton as Skeleton } from '@thg-commerce/gravity-elements'

const Container = styled(Skeleton)`
  display: none;
  ${(props) => mq(props.theme.breakpointUtils.map, 'md')} {
    display: block;
    height: 37px;
  }
`

export const Heading = () => {
  return (
    <Container>
      <rect x="0" y="0" rx="3" ry="3" width="60" height="18" />
      <rect
        x="0"
        y="0"
        rx="3"
        ry="3"
        width="60"
        height="18"
        style={{ transform: 'translate(calc(100% - 152px), 0)' }}
      />
      <rect
        x="0"
        y="0"
        rx="3"
        ry="3"
        width="60"
        height="18"
        style={{ transform: 'translate(calc(100% - 310px), 0)' }}
      />
      <rect
        x="0"
        y="0"
        rx="3"
        ry="3"
        width="60"
        height="18"
        style={{ transform: 'translate(calc(100% - 490px), 0)' }}
      />
      <rect x="0" y="34" rx="3" ry="3" width="100%" height="3" />
    </Container>
  )
}
