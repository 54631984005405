import { SectionPeekWidgetProductBlockListVariables } from '@thg-commerce/enterprise-network/src/ApolloProvider/resolvers/Query/SectionPeekWidgetProductBlockList'
import { ProductBlockListData } from '@thg-commerce/enterprise-network/src/ApolloProvider/resolvers/Query/Content/ProductBlockList'

import { SectionPeekWidgetProductBlockList } from './SectionPeekWidgetProductBlockList.graphql'
import { FallbackDataLoader, RecommendationSourceType } from '../../types'

export const loadSectionPeekProducts: FallbackDataLoader = async (
  source,
  apolloClient,
) => {
  if (source.type !== RecommendationSourceType.SECTION_PEEK_WIDGET) {
    return []
  }

  const { data } = await apolloClient.query<
    {
      sectionPeekWidgetProductBlockList: ProductBlockListData
    },
    SectionPeekWidgetProductBlockListVariables
  >({
    query: SectionPeekWidgetProductBlockList,
    variables: {
      path: source.path,
      widgetId: source.widgetId.toString(),
      currency: source.currency,
      shippingDestination: source.shippingDestination,
    },
  })

  const products = data?.sectionPeekWidgetProductBlockList.map((product) => {
    return {
      ...product,
      inStock: product.variants.some((variant) => variant.inStock),
    }
  })

  return products ?? []
}
