import * as React from 'react'

import { EmbedWrapper } from './style'
import { GenericEmbedProps } from './types'

interface FacebookProps extends GenericEmbedProps {
  title?: string
}

export const Facebook = (props: FacebookProps) => {
  const isVideo = props.url.includes('video')
  const width = props?.width || '500'
  const height = props?.height || isVideo ? '280' : '759'
  const facebookUrl = isVideo
    ? new URL('https://www.facebook.com/plugins/video.php')
    : new URL('https://www.facebook.com/plugins/post.php')
  facebookUrl.searchParams.set('href', props.url)
  facebookUrl.searchParams.set('width', width.replace(/\D/g, ''))
  facebookUrl.searchParams.set('height', height.replace(/\D/g, ''))
  facebookUrl.searchParams.set('show_text', 'true')
  facebookUrl.searchParams.set('appId', '')

  return (
    <EmbedWrapper>
      <iframe
        title={props.title || 'Facebook Post'}
        src={facebookUrl.toString()}
        frameBorder="0"
        width={width}
        height={height}
        allow="clipboard-write; encrypted-media; picture-in-picture"
        allowFullScreen
        scrolling="no"
      ></iframe>
    </EmbedWrapper>
  )
}
