import * as React from 'react'
import Head from 'next/head'

import { EmbedWrapper } from './style'
import { GenericEmbedProps } from './types'

export const TikTok = (props: GenericEmbedProps) => {
  const regex = /(?:https?:\/\/www\.)?tiktok\.com\S*?\/video\/(\w{19})\/?/
  const match = props.url.match(regex)
  const videoId = match && match[1]

  return !props.isAmp && videoId ? (
    <React.Fragment>
      <Head>
        <script
          key="tiktok-embed"
          id="tiktok-embed"
          async
          src="https://www.tiktok.com/embed.js"
        ></script>
      </Head>
      <EmbedWrapper>
        <blockquote
          className="tiktok-embed"
          cite={props.url}
          data-video-id={videoId}
          style={{ maxWidth: '605px', minWidth: '325px', overflow: 'hidden' }}
        >
          <section>
            <a target="_blank" rel="noopener noreferrer" href={props.url}>
              {props.url}
            </a>
          </section>
        </blockquote>
      </EmbedWrapper>
    </React.Fragment>
  ) : null
}
