import { ApolloClient, NormalizedCacheObject } from 'apollo-boost'

import {
  Country,
  Currency,
  ProductRecommendationType,
} from '@thg-commerce/enterprise-network/src/generated/graphql'
import { ProductBlockListData } from '@thg-commerce/enterprise-network/src/ApolloProvider/resolvers/Query/Content/ProductBlockList'

export enum RecommendationSourceType {
  PRODUCT_PAGE = 'product_page',
  BASKET_PAGE = 'basket_page',
  SECTION_PEEK_WIDGET = 'section_peek_widget',
  QUBIT_HOMEPAGE_RECS_WIDGET = 'qubit_homepage_recs_widget',
}

export interface AbstractRecommendationSource {
  type: RecommendationSourceType
}

export interface ProductPageSource extends AbstractRecommendationSource {
  type: RecommendationSourceType.PRODUCT_PAGE
  sku: number
  currency: Currency
  shippingDestination: Country
  recommendationsType: ProductRecommendationType
}

export interface SectionPeekWidgetSource extends AbstractRecommendationSource {
  type: RecommendationSourceType.SECTION_PEEK_WIDGET
  path: string
  widgetId: number
  currency: Currency
  shippingDestination: Country
}

export interface QubitHomePageRecsWidgetSource
  extends AbstractRecommendationSource {
  type: RecommendationSourceType.QUBIT_HOMEPAGE_RECS_WIDGET
  path: string
  widgetId: number
  currency: Currency
  shippingDestination: Country
}

export interface BasketPageSource extends AbstractRecommendationSource {
  type: RecommendationSourceType.BASKET_PAGE
  currency: Currency
  shippingDestination: Country
  id: string
  vipPriceEnabled?: boolean
  clickAndCollectEnabled?: boolean
  productContentKeys?: string[]
}

export type RecommendationSource =
  | ProductPageSource
  | BasketPageSource
  | SectionPeekWidgetSource
  | QubitHomePageRecsWidgetSource

export type FallbackDataLoader = (
  source: RecommendationSource,
  apolloClient: ApolloClient<NormalizedCacheObject>,
) => Promise<ProductBlockListData>
