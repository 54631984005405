import { styled, Text, spacing, mq } from '@thg-commerce/gravity-theme'
import { Button } from '@thg-commerce/gravity-elements'

export const Title = styled.p`
  ${Text('large1', 'alternate')}
`

export const Subtitle = styled.p`
  margin-top: ${spacing(2)};
`

export const CtaWrapper = styled.div<{ fullWidthButtons?: boolean }>`
  margin-top: ${spacing(4)};

  ${(props) => mq(props.theme.breakpointUtils.map, 'sm')} {
    display: flex;
    ${(props) => props.fullWidthButtons && 'flex-direction:column-reverse;'}
    gap: ${spacing(2)};
  }
`

export const ConfirmButton = styled(Button)`
  width: 100%;
  margin-top: ${spacing(2)};

  ${(props) => mq(props.theme.breakpointUtils.map, 'sm')} {
    margin-top: 0;
  }
`

export const CancelButton = styled(Button)`
  width: 100%;
`
