import * as React from 'react'

import { ImageGalleryContext, Scale } from './ImageGalleryContext'

export const ImageGalleryContextProvider: React.FunctionComponent<{
  children: React.ReactElement
  zoomButtonClickHandler?: (index: number) => void
}> = (props) => {
  const fullScreenActive = React.useState(false)
  const scale = React.useState(Scale.MIN)
  const showZoomButtonsAccessibilityLabel = React.useState(false)
  return (
    <ImageGalleryContext.Provider
      value={{
        fullScreenActive,
        scale,
        showZoomButtonsAccessibilityLabel,
        zoomButtonClickHandler: props.zoomButtonClickHandler,
      }}
    >
      {props.children}
    </ImageGalleryContext.Provider>
  )
}
