import * as React from 'react'
import { TransformWrapper } from 'react-zoom-pan-pinch'
import loadable from '@loadable/component'

import { DrawFocus, Image } from '@thg-commerce/gravity-elements'
import {
  BreakpointArray,
  css,
  Direction,
  mediaQueryRenderer,
  mq,
  spacing,
  styled,
  Text,
  zIndex,
  ZIndexLevel,
} from '@thg-commerce/gravity-theme'

import { ImageCarousel } from './ImageCarousel'
import { FullscreenComponentProp, Position, ThumbnailPadding } from './types'

const ChevronLeft = loadable(
  () => import('@thg-commerce/gravity-icons/src/components/ChevronLeft'),
  { ssr: true, fallback: <div style={{ width: 24, height: 24 }} /> },
)
const ChevronRight = loadable(
  () => import('@thg-commerce/gravity-icons/src/components/ChevronRight'),
  { ssr: true, fallback: <div style={{ width: 24, height: 24 }} /> },
)

const THUMBNAIL_SIZE = 48

export const StyledImage = styled(Image)<{
  scale?: number
}>`
  width: 100%;
  height: 100%;
  cursor: ${(props) => (props.scale === 1 ? 'default' : 'move')};
  user-drag: none;
  user-select: none;
  pointer-events: none;
  object-fit: cover;

  ${(props) => mq(props.theme.breakpointUtils.map, 'sm')} {
    object-fit: contain;
  }
`

export const Container = styled.div<
  FullscreenComponentProp & {
    direction: BreakpointArray<Direction>
    height?: BreakpointArray<number>
  }
>`
  position: relative;
  height: 100%;

  ${(props) =>
    props.fullscreen &&
    css`
      ${zIndex(ZIndexLevel.Higher)};

      position: fixed;
      width: 100%;
      height: 100%;
      background-color: ${props.theme.colors.palette.greys.white}
      inset: 0;
  `}

  ${(props) =>
    props.height &&
    !props.fullscreen &&
    mediaQueryRenderer(
      props.height,
      (height, breakpoint) => `
  height: ${
    props.direction[breakpoint] === Direction.COLUMN
      ? `${height + THUMBNAIL_SIZE}px`
      : `${height}px`
  };
      `,
    )}
`

export const Header = styled.div<FullscreenComponentProp>`
  position: absolute;
  inset: 0 -1px 0 0;
  height: ${THUMBNAIL_SIZE}px;

  ${(props) =>
    props.fullscreen &&
    css`
      border-bottom: 1px solid ${props.theme.colors.palette.brand.base};
      margin-top: 1px;
    `}
`

export const StyledTransformWrapper = styled(TransformWrapper)`
  position: relative;
  height: 100%;
`

export const TitleWrapper = styled.div`
  width: calc(100% - ${THUMBNAIL_SIZE}px);
  height: 100%;
  display: flex;
`

export const Title = styled.p<FullscreenComponentProp>`
  ${Text('bodyText', 'alternate')};

  margin: auto;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  ${(props) => !props.fullscreen && `display: none;`}
`

export const controlsLocationMap = {
  [Position.TOP_LEFT]: css`
    left: 0px;
    top: 0px;
  `,
  [Position.TOP_RIGHT]: css`
    right: 0px;
  `,
  [Position.TOP_MIDDLE]: css`
    right: 50%;
    top: 10px;
    transform: translateX(-10px);
    height: 48px;
  `,
  [Position.BOTTOM_LEFT]: css`
    bottom: 0px;
  `,
  [Position.BOTTOM_RIGHT]: css`
    right: 0px;
    bottom: 0px;
  `,
  [Position.BOTTOM_RIGHT_SPACED]: css`
    right: 10px;
    bottom: 10px;
  `,
}

export const ControlsWrapper = styled.div<
  FullscreenComponentProp & {
    location: Position
    fullscreenButton?: boolean
    applyPadding?: boolean
  }
>`
  display: flex;
  position: absolute;
  margin: ${(props) => (props.applyPadding ? spacing(1) : '0px')};

  ${(props) => controlsLocationMap[props.location]}

  ${(props) => mq(props.theme.breakpointUtils.map, 'md')} {
    ${(props) => controlsLocationMap[props.location]}
  }
  ${(props) => mq(props.theme.breakpointUtils.map, 'sm')} {
    ${(props) =>
      !props.fullscreenButton &&
      controlsLocationMap[props.location || Position.TOP_MIDDLE]}
  }

  ${(props) =>
    props.fullscreen ? zIndex(ZIndexLevel.Higher) : zIndex(ZIndexLevel.Base)};

  ${(props) => props.fullscreenButton && 'right: 0px; bottom: 0px;'};
  }
`

export const ZoomWrapper = styled.div`
  display: flex;
`

// TODO REBUILD-6636 remove display none for sizes above SM to implement on desktop
export const OpenZoomControl = styled.button<{
  size: BreakpointArray<number>
  controlPosition?: BreakpointArray<Position>
  desktopZoomEnabled?: boolean
  location?: Position
}>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${(props) => props.size}px;
  height: ${(props) => props.size}px;
  background-color: ${(props) => props.theme.colors.palette.greys.white};
  border: 0px solid ${(props) => props.theme.colors.palette.brand.base};
  fill: ${(props) => props.theme.colors.palette.brand.base};
  stroke: ${(props) => props.theme.colors.palette.brand.base};

  ${(props) =>
    props.controlPosition &&
    mediaQueryRenderer(
      props.controlPosition,
      (controlPosition) => `
        display: ${props.location === controlPosition ? 'block' : 'none'};
      `,
    )}

  ${(props) =>
    mediaQueryRenderer(
      props.size,
      (size) => `
        width: ${size}px;
        height: ${size}px;
      `,
    )}

  &:focus {
    outline: none;
  }

  ${(props) => mq(props.theme.breakpointUtils.map, 'sm')} {
    display: ${(props) => (props.desktopZoomEnabled ? 'flex' : 'none')};
  }
`

export const Control = styled.button<{
  size: BreakpointArray<number>
  disabled?: boolean
  controlPosition?: BreakpointArray<Position>
  location?: Position
  zoomEnabled?: boolean
  borderColor?: string
  backgroundColor?: string
}>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${(props) => props.size}px;
  height: ${(props) => props.size}px;
  background-color: ${(props) => props.backgroundColor};
  border: 1px solid ${(props) => props.borderColor};
  fill: ${(props) => props.theme.colors.palette.greys.light};
  ${(props) => props.zoomEnabled && 'border-right: none;'}

  ${(props) =>
    props.controlPosition &&
    mediaQueryRenderer(
      props.controlPosition,
      (controlPosition) => `
        display: ${props.location === controlPosition ? 'block' : 'none'};
      `,
    )}

  ${(props) =>
    mediaQueryRenderer(
      props.size,
      (size) => `
        width: ${size}px;
        height: ${size}px;
      `,
    )}

  fill: ${(props) =>
    props.disabled
      ? props.theme.colors.palette.greys.light
      : props.theme.colors.palette.brand.base};

  stroke: ${(props) =>
    props.disabled
      ? props.theme.colors.palette.greys.light
      : props.theme.colors.palette.brand.base};

  border: 1px solid
    ${(props) =>
      props.disabled
        ? props.theme.colors.palette.greys.light
        : props.borderColor};

  &:focus {
    ${DrawFocus()}
    margin: 0px;
    ${(props) =>
      !props.zoomEnabled &&
      `border: 2px solid ${(props) => props.theme.colors.palette.brand.base};`}
  }
`

export const ContentWrapper = styled.div<{
  direction: BreakpointArray<Direction>
  fullScreen: boolean
  gapSpacing?: BreakpointArray<number>
}>`
  display: flex;
  height: 100%;

  ${(props) =>
    mediaQueryRenderer(
      props.gapSpacing || [2],
      (gap) => `gap: ${spacing(gap)};`,
    )}

  ${(props) =>
    mediaQueryRenderer(
      props.direction,
      (direction) => `
        flex-direction: ${direction};
      `,
    )}

  ${(props) =>
    props.fullScreen &&
    css`
      justify-content: space-between;
      height: 100%;
      max-height: calc(100vh - ${spacing(6)});
      margin-top: ${spacing(6)};

      @media (orientation: landscape) {
        flex-direction: row;
      }
    `}
`

export const CarouselWrapper = styled.div<
  FullscreenComponentProp & {
    height?: BreakpointArray<number>
    direction: BreakpointArray<Direction>
  }
>`
  width: 100%;
  height: 100%;
  position: relative;

  ${(props) =>
    props.height &&
    !props.fullscreen &&
    mediaQueryRenderer(props.height, (height) => `height: ${height}px`)}

  ${(props) =>
    mediaQueryRenderer(props.direction, (direction) => {
      return direction === Direction.ROW
        ? 'order: 2; width: calc(100% - 97px);'
        : 'order: 1; width: 100%;'
    })}

  ${(props) =>
    props.fullscreen &&
    css`
      order: 1;
      max-height: calc(100vh - ${spacing(6)}px);

      ${(props) => mq(props.theme.breakpointUtils.map, 'sm')} {
        max-height: calc(100vh - 238px);
      }

      ${(props) =>
        mq(props.theme.breakpointUtils.map, 'md')}, (orientation: landscape) {
        width: calc(100% - 96px);
        order: 2;
        max-height: calc(100vh - 96px);
      }
    `}
`

export const ThumbnailsWrapper = styled.div<
  FullscreenComponentProp & {
    direction: BreakpointArray<Direction>
    marginX?: BreakpointArray<number>
    thumbnailPadding?: ThumbnailPadding
  }
>`
  ${(props) =>
    props.thumbnailPadding && `padding: ${props.thumbnailPadding.xs};`}
  ${(props) =>
    !props.fullscreen &&
    mediaQueryRenderer(props.direction, (direction) =>
      direction === Direction.ROW
        ? `
          order: 1;
        `
        : `
          order: 2;
        `,
    )}

  ${(props) =>
    !props.fullscreen &&
    props.marginX &&
    mediaQueryRenderer(props.marginX, (marginX) =>
      marginX ? `margin: 0 ${spacing(marginX)}` : `margin: 0`,
    )};

  ${(props) =>
    props.fullscreen &&
    css`
      background-color: ${(props) => props.theme.colors.palette.greys.white};
      border-right: 1px solid
        ${(props) => props.theme.colors.palette.brand.base};
      order: 2;

      ${(props) =>
        mq(props.theme.breakpointUtils.map, 'md')}, (orientation: landscape) {
        order: 1;
        padding-top: 0;
      }
    `}

  ${(props) => mq(props.theme.breakpointUtils.map, 'sm')} {
    ${(props) =>
      props.thumbnailPadding && `padding: ${props.thumbnailPadding.sm};`}
  }

  ${(props) => mq(props.theme.breakpointUtils.map, 'md')} {
    height: ${(props) => (props.fullscreen ? '100%' : 'auto')};
  }
`

export const Footer = styled.div<
  FullscreenComponentProp & { show: BreakpointArray<boolean> }
>`
  display: flex;
  position: absolute;
  bottom: 0;
  width: 100%;

  ${(props) =>
    !props.fullscreen &&
    mediaQueryRenderer(
      props.show,
      (show) => `
        display: ${show ? 'flex' : 'none'};
      `,
    )}
`

const AccessibilityLabelFullscreenDesktopStyles = css`
  position: fixed;
  top: ${THUMBNAIL_SIZE}px;
  right: 0;
  width: auto;
`

export const AccessibilityLabel = styled.div<FullscreenComponentProp>`
  display: flex;
  position: absolute;
  width: calc(100% - 96px);
  height: ${THUMBNAIL_SIZE}px;
  bottom: 0;
  padding: ${spacing(1)} ${spacing(2)};
  align-items: center;

  z-index: ${(props) => (props.fullscreen ? '26' : '1')};
  background-color: ${(props) => props.theme.colors.palette.greys.lighter};

  ${(props) => props.fullscreen && AccessibilityLabelFullscreenDesktopStyles}

  ${(props) =>
    mq(props.theme.breakpointUtils.map, 'md')}, (orientation: landscape) {
    width: calc(100% - 144px);
    top: 0;
    ${(props) => props.fullscreen && AccessibilityLabelFullscreenDesktopStyles}
  }
`

export const AccessibilityLabelText = styled.p`
  ${Text('xsmall', 'default')};
`

export const CarouselControls = styled.div<{
  fullScreen: boolean
}>`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin: ${spacing(1)};

  ${(props) => mq(props.theme.breakpointUtils.map, 'md')} {
    right: ${(props) => (props.fullScreen ? `${THUMBNAIL_SIZE}px` : '0')};
  }
`

export const StyledImageCarousel = styled(ImageCarousel)`
  width: 100%;
  height: 100%;

  ${(props) =>
    props.fullscreen &&
    css`
      ul {
        height: 100%;
      }

      img {
        object-fit: cover;
      }

      ${(props) =>
        mq(props.theme.breakpointUtils.map, 'md')}, (orientation: landscape) {
        height: 100%;
      }
    `}
`

export const StyledChevronLeft = styled(ChevronLeft)`
  svg {
    fill: ${(props) => props.theme.colors.palette.brand.base};
  }
`

export const StyledChevronRight = styled(ChevronRight)`
  svg {
    fill: ${(props) => props.theme.colors.palette.brand.base};
  }
`
