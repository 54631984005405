import { ApolloClient, NormalizedCacheObject } from 'apollo-boost'
import { ProductBlockListData } from '@thg-commerce/enterprise-network/src/ApolloProvider/resolvers/Query/Content/ProductBlockList'

import { RecommendationSource, RecommendationSourceType } from '../types'
import { loadProductRecommendations } from './ProductRecommendations/data'
import { loadBasketRecommendations } from './BasketRecommendations/data'
import { loadSectionPeekProducts } from './SectionPeekWidget/data'
import { loadQubitHomePageRecsProducts } from './QubitHomePageRecsWidget/data'

type FetchMapType = {
  [type in RecommendationSourceType]: (
    source: RecommendationSource,
    apolloClient: ApolloClient<NormalizedCacheObject>,
  ) => Promise<ProductBlockListData>
}

const FETCH_MAP: FetchMapType = {
  [RecommendationSourceType.PRODUCT_PAGE]: loadProductRecommendations,
  [RecommendationSourceType.BASKET_PAGE]: loadBasketRecommendations,
  [RecommendationSourceType.SECTION_PEEK_WIDGET]: loadSectionPeekProducts,
  [RecommendationSourceType.QUBIT_HOMEPAGE_RECS_WIDGET]: loadQubitHomePageRecsProducts,
}

export const fetchFallbackData = async (
  source: RecommendationSource,
  apolloClient: ApolloClient<NormalizedCacheObject>,
): Promise<ProductBlockListData> => {
  if (FETCH_MAP[source.type]) {
    return await FETCH_MAP[source.type](source, apolloClient)
  }
  return []
}
