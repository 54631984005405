import * as React from 'react'
import { vsprintf } from 'sprintf-js'

import { BasketData } from '@thg-commerce/enterprise-network/src/ApolloProvider/resolvers/Query/Basket/Basket'
import { i18n, MessageArea } from '@thg-commerce/enterprise-core'
import { AriaMessageType } from '@thg-commerce/gravity-elements'

import { ProgressBar } from '../ProgressBar'
import { Product } from './Product'
import {
  QualifiedContainer,
  StyledIconSuccess,
  SelectedGifts,
  TierContainer,
  QualifiedText,
  StyledPlatformMessage,
} from './styles'

export const Qualified = (props: {
  text: string
  thresholdAmountSpent?: string
}) => {
  return (
    <QualifiedContainer>
      <StyledIconSuccess />
      <QualifiedText>
        {props.text}
        {props.thresholdAmountSpent && ` ${props.thresholdAmountSpent}`}
      </QualifiedText>
    </QualifiedContainer>
  )
}

const renderAnnouncer = (ariaLive: AriaMessageType, message: string) => {
  return <MessageArea aria-live={ariaLive} message={message} />
}

export interface TierProps {
  id: string
  products: BasketData['selectYourSample'][0]['tiers'][0]['products']
  isFullyOutOfStock: boolean
  isPartiallyOutOfStock: boolean
  selectedProducts: BasketData['selectYourSample'][0]['tiers'][0]['selectedProducts']
  currentAmountSpent: number
  additionalSpendToReach: {
    amount: number
    displayValue: string
  }
  maxSelectedProducts: number
  thresholdAmountSpent?: string | false
}

export const Tier = (props: TierProps) => {
  const i18nText = {
    selectedGifts: i18n('basket.selectyoursample.gifts.selected.text'),
    qualified: i18n('general.qualified.text'),
    thresholdAmountSpentText: i18n(
      'basket.selectyoursample.tier.threshold.text',
    ),
    fullyOutOfStockText: i18n('basket.selectyoursample.tier.oos.text'),
    partiallyOutOfStockText: i18n('basket.selectyoursample.oos.text'),
  }

  const meta = !props.additionalSpendToReach.amount ? (
    <React.Fragment>
      <Qualified
        text={i18nText.qualified}
        thresholdAmountSpent={
          props.thresholdAmountSpent
            ? vsprintf(i18nText.thresholdAmountSpentText, [
                props.thresholdAmountSpent,
              ])
            : undefined
        }
      />
      <SelectedGifts data-testid="qualified">
        {vsprintf(i18nText.selectedGifts, [
          props.selectedProducts.length,
          props.maxSelectedProducts,
        ])}
      </SelectedGifts>
      {props.isFullyOutOfStock && (
        <StyledPlatformMessage
          data-testid="fully-out-of-stock-message"
          type="info"
          text={i18nText.fullyOutOfStockText}
          renderAnnouncer={renderAnnouncer}
          iconBoxWidth={20}
        />
      )}
      {props.isPartiallyOutOfStock && (
        <StyledPlatformMessage
          data-testid="partially-out-of-stock-message"
          type="info"
          text={i18nText.partiallyOutOfStockText}
          renderAnnouncer={renderAnnouncer}
          iconBoxWidth={20}
        />
      )}
    </React.Fragment>
  ) : (
    <React.Fragment>
      <ProgressBar
        additionalSpendToReach={{
          amount: props.additionalSpendToReach.amount,
          displayValue: props.additionalSpendToReach.displayValue,
        }}
        currentAmountSpent={props.currentAmountSpent}
        isOpen
      />
    </React.Fragment>
  )

  return (
    <TierContainer>
      {meta}
      <Product
        products={props.products}
        selectedProducts={props.selectedProducts}
        tierId={props.id}
        isQualified={!props.additionalSpendToReach.amount}
        isSelectable={
          props.selectedProducts.length !== props.maxSelectedProducts
        }
      />
    </TierContainer>
  )
}
