import * as React from 'react'

import { ProductDescriptionAccordionProps as GravityProductDescriptionAccordionProps } from '@thg-commerce/gravity-patterns'
import { theme } from '@thg-commerce/gravity-theme'
import {
  ProductContentSafeHTML,
  StyledDeliveryInfoAndReturnsWrapper,
} from '@thg-commerce/gravity-patterns/ProductDescriptionAccordion/styles'
import { useSiteConfig, i18n } from '@thg-commerce/enterprise-core'
import { SocialEmbed } from '@thg-commerce/enterprise-widget-social-embed'
import { DeliveryAndReturnsInfo } from '@thg-commerce/enterprise-components/DeliveryAndReturnsInfo'
import { ProvenanceBundle } from '@thg-commerce/enterprise-components'
import { ProductPageData } from '@thg-commerce/enterprise-network/src/ApolloProvider/resolvers/Query/Product/ProductPage'

const SAFEHTML_ALLOWED_TAGS = [
  'table, caption, colgroup, col, th, thead, tbody, tr, td, tfoot',
  'strong',
  'em',
  'b',
  'span',
  'img',
  'small',
]

export const useProductDescriptionAccordionTransformer = () => {
  const {
    showDeliveryAndReturnsInfoInAccordion,
    showProductProvenance,
    videoAccordionBelowProductDescription,
  } = useSiteConfig()

  const i18nText = {
    provenanceTabHeading: i18n('product.provenancetab.heading.text'),
    productDetail: i18n('product.content.productdetails.title.text'),
    videoTitle: i18n('product.content.productvideo.title.text'),
    deliveryTitle: i18n('deliveryandreturnsinfo.delivery.heading'),
  }

  const transform = (
    description: ProductPageData['description'],
    isDesktop: boolean,
    sku: number,
  ) => {
    if (!description) {
      return {
        customTopItems: [],
        customBottomItems: [],
      }
    }

    const customTopItems: NonNullable<
      GravityProductDescriptionAccordionProps['customTopItems']
    > = []
    const customBottomItems: NonNullable<
      GravityProductDescriptionAccordionProps['customBottomItems']
    > = []

    if (description.videoReference) {
      const youtubeUrl = `https://www.youtube.com/embed/${description.videoReference}?rel=0&showinfo=0`

      const videoItemPosition = videoAccordionBelowProductDescription
        ? customBottomItems
        : customTopItems

      videoItemPosition.unshift({
        title: (
          <React.Fragment>
            {description.videoTitle || i18nText.videoTitle}
          </React.Fragment>
        ),
        key: 'productVdeo',
        content: (
          <SocialEmbed provider="youtube" url={youtubeUrl} isAmp={false} />
        ),
      })
    }

    if (showDeliveryAndReturnsInfoInAccordion) {
      customBottomItems.push({
        title: (
          <React.Fragment>
            {description.deliveryTitle || i18nText.deliveryTitle}
          </React.Fragment>
        ),
        key: 'deliveryandreturnsinfo',
        content: (
          <StyledDeliveryInfoAndReturnsWrapper>
            <DeliveryAndReturnsInfo showOnlyContent={true} />
          </StyledDeliveryInfoAndReturnsWrapper>
        ),
      })
    }

    if (
      showProductProvenance &&
      description.provenance.enabled &&
      description.provenance.textItem
    ) {
      const windowWidth =
        typeof window !== 'undefined'
          ? window.innerWidth
          : theme.breakpointUtils.map.xs

      customBottomItems.push({
        title: <React.Fragment>{i18nText.provenanceTabHeading}</React.Fragment>,
        key: 'provenanceAccordion',
        keepWhenClose: true,
        content: (
          <React.Fragment>
            <ProductContentSafeHTML
              removeTagsAndContent={false}
              __dangerouslyAllowedTags={SAFEHTML_ALLOWED_TAGS}
              content={description.provenance.textItem}
            />
            {
              // The [sku].tsx currently renders two productDescriptionAccordions but depending on breakpoints
              // We have this check to prevent Provenance from rendering twice on the DOM based on the breakpoint
              // Provenance SDK will not work if there are multiple of them in the DOM
            }
            {((isDesktop && windowWidth >= theme.breakpointUtils.map.md) ||
              (!isDesktop && windowWidth < theme.breakpointUtils.map.md)) && (
              <ProvenanceBundle productId={sku} />
            )}
          </React.Fragment>
        ),
      })
    }

    return {
      customTopItems,
      customBottomItems,
    }
  }

  return transform
}
