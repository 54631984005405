import * as React from 'react'

import {
  i18n,
  useFormattableI18nProperty,
  useLogger,
  useSiteConfig,
  useTheme,
} from '@thg-commerce/enterprise-core'
import { CallbackSetEvent } from '@thg-commerce/enterprise-network/src/ApolloProvider/resolvers/Types/Qubit'
import { ProductPriceProps } from '@thg-commerce/gravity-elements'
import { ZIndexLevel } from '@thg-commerce/gravity-theme'
import { sendImpression } from '@thg-commerce/enterprise-components/Qubit/qubitImpressions'
import { MarketedSpecialOfferSummary } from '@thg-commerce/enterprise-network/src/transformers/product/marketedSpecialOffer'

import { ProductBlock } from '../../Product'

export interface ProductProps extends ProductPriceProps {
  sku: string
  url: string
  title: string
  imageUrl: string
  reviews?: {
    total: number
    averageScore: number
  } | null
  inStock?: boolean
  marketedSpecialOffer?: MarketedSpecialOfferSummary | null
}

type ProductRecommendationRailProps = {
  qubitCallbackData: string
  product: ProductProps
}

export const ProductRecommendationRail = ({
  product,
  qubitCallbackData,
}: ProductRecommendationRailProps) => {
  const logger = useLogger()
  const { qubit } = useSiteConfig()
  const theme = useTheme()

  const screenReaderReviewText = ({ score, total }) =>
    `${score} ${productI18nText.reviewsStarsLabel}
  ${total} ${productI18nText.reviewsLabel}`

  const productI18nText = {
    buyAriaLabel: useFormattableI18nProperty('general.productbuynow.label'),
    buyText: i18n('general.productbuynow.text'),
    quickBuyText: i18n('general.product.quickbuy.text'),
    fromText: i18n('product.price.from.text'),
    reviewsStarsLabel: i18n('reviews.stars.label'),
    reviewsLabel: i18n('reviews.reviews.label'),
    rrpText: i18n('product.price.rrp.text'),
    closeI18nText: {
      closeAriaLabel: i18n('general.modal.close.button.arialabel'),
      closeLabel: i18n('general.modal.close.button.label'),
    },
    freeGiftMessage: i18n('product.marketedspecialoffer.freegift.text'),
  }

  const onProductClick = () => {
    sendImpression({
      logger,
      eventType: CallbackSetEvent.CLICK_THROUGH,
      callbackData: qubitCallbackData,
      callbackURL: qubit?.callbackURL,
    })
    sendImpression({
      logger,
      sku: product.sku,
      eventType: CallbackSetEvent.CLICK_THROUGH,
      callbackData: qubitCallbackData,
      callbackURL: qubit?.callbackURL,
    })
  }

  return (
    <div onClick={onProductClick} role={'button'}>
      <ProductBlock
        sku={product.sku}
        title={{ value: product.title, useAlternateStyle: false }}
        url={product.url}
        image={{
          isAmp: false,
          lazy: true,
          urls: { largeProduct: product.imageUrl },
        }}
        marketedSpecialOffer={
          product.marketedSpecialOffer
            ? {
                ...product.marketedSpecialOffer,
                i18nText: {
                  closeI18nText: productI18nText.closeI18nText,
                  freeGiftMessage: productI18nText.freeGiftMessage,
                },
              }
            : undefined
        }
        review={
          product.reviews
            ? {
                starRating: product.reviews.averageScore,
                numberOfReviews: product.reviews.total,
                screenReaderOnlyText: screenReaderReviewText({
                  score: product.reviews.averageScore,
                  total: product.reviews.total,
                }),
              }
            : undefined
        }
        price={{
          rrpText: productI18nText.rrpText,
          price: product.price,
          rrp: product.rrp,
        }}
        button={{
          title: productI18nText.buyText,
          ariaLabel: productI18nText.buyAriaLabel(product.title),
          quickBuyTitle: productI18nText.quickBuyText,
          productInStock: product.inStock,
        }}
        fromRecommendations={true}
        qubitBadgeStyle={theme.widget.productList.qubitBadge}
        qubitBadgeZIndex={ZIndexLevel.Low}
      />
    </div>
  )
}
