import * as React from 'react'

export enum Scale {
  MAX = 2.5,
  MIN = 1,
  STEP = 0.5,
}

export interface ImageGalleryContextInterface {
  fullScreenActive: [boolean, React.Dispatch<React.SetStateAction<boolean>>]
  scale: [number, React.Dispatch<React.SetStateAction<number>>]
  showZoomButtonsAccessibilityLabel: [
    boolean,
    React.Dispatch<React.SetStateAction<boolean>>,
  ]
  zoomButtonClickHandler?: (imageIndex: number) => void
}

export const ImageGalleryContext = React.createContext<
  ImageGalleryContextInterface
>({
  // @TODO REBUILD-6636 remove all instances of fullScreenActive from context and related files
  fullScreenActive: [false, () => {}],
  scale: [Scale.MIN, () => {}],
  showZoomButtonsAccessibilityLabel: [false, () => {}],
  zoomButtonClickHandler: () => {},
})

export const useImageGalleryContext = () =>
  React.useContext(ImageGalleryContext)
