import { SafeHtml } from '@thg-commerce/gravity-elements'
import {
  spacing,
  Text,
  margin,
  paddingMixin,
} from '@thg-commerce/gravity-theme'

import { styled } from '../theme'

export const StyledDeliveryInfoAndReturnsWrapper = styled.div`
  ${(props) =>
    margin(
      props.theme.patterns.productDescriptionAccordion.deliveryAndReturnsInfo
        .margin,
    )}
`

export const ProductContentSafeHTML = styled(SafeHtml)`
  ${(props) =>
    paddingMixin(
      props.theme.patterns.productDescriptionAccordion.container.padding,
    )};

  ul {
    margin-left: calc(${spacing(2)} + 2px);
  }

  p {
    ${Text('bodyText', 'default')};
    margin-bottom: ${spacing(1.5)};
  }

  strong {
    ${Text('bodyText', 'alternate')};
  }

  h2 {
    ${Text('medium1', 'alternate')};
  }

  h3 {
    ${Text('bodyText', 'alternate')};
  }

  em {
    font-style: italic;
  }

  table {
    margin-top: ${spacing(1)};
  }
`

export const Tab = styled.div`
  border-top: 1px solid ${(props) => props.theme.colors.palette.greys.light};
  overflow: hidden;
  width: 100%;

  &:last-of-type {
    border-bottom: 1px solid
      ${(props) => props.theme.colors.palette.greys.light};
  }
`

export const SvgWrapper = styled.div`
  position: absolute;
  right: calc(${spacing(1)} + 2px);
  top: calc(${spacing(1)} + 2px);
  transform: rotate(0);
`

export const StyledLabel = styled.label`
  cursor: pointer;
  display: flex;
  font-weight: bold;
  justify-content: space-between;
  padding: calc(${spacing(1)} + 2px);
  position: relative;
  ${Text('bodyText', 'alternate')};
`

export const TabContent = styled.div`
  display: none;
  max-height: 0;
  visibility: hidden;
`

export const StyledInput = styled.input`
  opacity: 0;
  position: absolute;
  z-index: -1;

  &:checked {
    + ${StyledLabel} ${SvgWrapper} {
      top: ${spacing(0.25)};
      transform: rotate(180deg);
    }

    ~ ${TabContent} {
      display: block;
      max-height: 100vh;
      visibility: initial;
    }
  }

  &:focus {
    + ${StyledLabel} {
      border: 2px solid ${(props) => props.theme.colors.palette.greys.dark};
      padding: ${spacing(1)};

      ${SvgWrapper} {
        right: ${spacing(1)};
        top: ${spacing(1)};
      }
    }
  }

  &:checked:focus + ${StyledLabel} ${SvgWrapper} {
    top: 0;
  }
`

export const ProductPromotionalMessage = styled.div`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: ${spacing(0.25)};
  ${(props) => `border: 1px solid ${props.theme.colors.palette.greys.darker} `};
`
