import React from 'react'
import loadable from '@loadable/component'

import { PageWrapper } from '@thg-commerce/enterprise-core'
import { Button } from '@thg-commerce/gravity-elements'
import { LoyaltyPoints } from '@thg-commerce/gravity-patterns'
import { GridItem } from '@thg-commerce/gravity-system'
import {
  mq,
  spacing,
  styled,
  Text,
  TextEntry,
} from '@thg-commerce/gravity-theme'
import { Margin } from '@thg-commerce/gravity-theme/margin'

const Lock = loadable(
  () => import('@thg-commerce/gravity-icons/src/components/Lock'),
  { ssr: true, fallback: <div style={{ width: 24, height: 24 }} /> },
)

import { CheckoutError } from '../CheckoutError'
import { Discount } from '../Discount'
import { Messages } from '../Messages'
import { OfferSummary } from '../OfferSummary'
import { PaymentIcons } from '../PaymentIcons'
import { Referrals } from '../Referrals'
import { SelectYourSample } from '../SelectYourSample'
import { Table } from '../Table'

export const PageContainer = styled.div`
  background-color: ${(props) => props.theme.colors.palette.greys.white};
`

export const SelectYourSampleGridItem = styled(GridItem)`
  height: 0;
  overflow: hidden;
  display: none;

  ${(props) => mq(props.theme.breakpointUtils.map, 'lg')} {
    height: auto;
    overflow: auto;
    margin: ${spacing(6)} ${spacing(4)} 0 0;
    display: block;
  }
`

export const PageGridItem = styled(GridItem)<{ margin?: Margin }>`
  margin: ${spacing(4)} ${spacing(2)};

  ${(props) => mq(props.theme.breakpointUtils.map, 'sm')} {
    margin: ${spacing(6)} ${(props) => props.margin?.right || `${spacing(4)}`}
      ${spacing(6)} ${spacing(4)};
  }
`

export const StyledPageWrapper = styled(PageWrapper)`
  margin: auto;
`

export const StyledCheckoutError = styled(CheckoutError)`
  margin-bottom: ${spacing(4)};
`

export const PageHeadingContainer = styled.div`
  margin-bottom: ${spacing(3)};
  align-items: flex-start;

  ${(props) => mq(props.theme.breakpointUtils.map, 'sm')} {
    display: flex;
    justify-content: space-between;
  }
`

export const HeadingCheckout = styled(Button)`
  width: 100%;

  ${(props) => mq(props.theme.breakpointUtils.map, 'sm')} {
    width: auto;
  }
`

export const Title = styled.h1`
  ${Text('large2', 'alternate')};
  margin-bottom: ${spacing(1)};
`

export const SubTitle = styled.h2`
  ${Text('bodyText', 'default')};
  color: ${(props) => props.theme.colors.palette.greys.dark};
  margin-bottom: ${spacing(2)};

  ${(props) => mq(props.theme.breakpointUtils.map, 'sm')} {
    margin-bottom: ${spacing(3)};
  }
`

export const SubTitleDesktop = styled.h2`
  display: none;

  ${(props) => mq(props.theme.breakpointUtils.map, 'sm')} {
    ${Text('bodyText', 'default')};
    color: ${(props) => props.theme.colors.palette.greys.grey};
    margin-bottom: ${spacing(3)};
    display: block;
  }
`

export const LockIcon = styled(Lock)`
  width: 16px;
  height: 19px;
  align-self: center;
  margin-right: ${spacing(2)};
`

export const CheckoutContainer = styled.div`
  margin: ${spacing(4)} 0 ${spacing(2)};
  order: 1;

  ${(props) => mq(props.theme.breakpointUtils.map, 'sm')} {
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin: ${spacing(3)} 0 ${spacing(2)};
    flex-wrap: wrap;
    gap: ${spacing(3)};
  }

  ${(props) => mq(props.theme.breakpointUtils.map, 'md')} {
    gap: ${spacing(1)};
  }
`

export const Checkout = styled(Button)`
  width: 100%;

  ${(props) => mq(props.theme.breakpointUtils.map, 'sm')} {
    width: auto;
    flex-grow: 1;
  }
`

export const StyledPaymentIcons = styled(PaymentIcons)<{
  order: string
  marginBottom: string
}>`
  order: ${(props) => props.order};
  margin-bottom: ${(props) => props.marginBottom};
  width: 100%;
`

export const ContinueShopping = styled(Button)<{
  order: string
  marginBottom: string
}>`
  order: ${(props) => props.order};
  width: 100%;
  margin-bottom: ${(props) => props.marginBottom};
`

export const StyledMessages = styled(Messages)<{
  lastMessage?: boolean
}>`
  margin-bottom: ${(props) => (props.lastMessage ? 0 : spacing(3))};

  ${(props) => mq(props.theme.breakpointUtils.map, 'md')} {
    margin-bottom: ${(props) => (props.lastMessage ? 0 : spacing(4))};
  }
`

export const StyledMessagesAlternate = styled.div`
  margin-bottom: ${spacing(3)};

  ${(props) => mq(props.theme.breakpointUtils.map, 'md')} {
    margin-bottom: ${spacing(4)};
  }
`

export const StyledOfferSummary = styled(OfferSummary)`
  margin-top: ${spacing(3)};
  width: 100%;

  ${(props) => mq(props.theme.breakpointUtils.map, 'md')} {
    margin-top: ${spacing(2)};
  }
`

export const StyledReferrals = styled(Referrals)`
  margin-bottom: ${spacing(2)};
`

export const Subtotal = styled.div`
  display: flex;
  justify-content: space-between;
`
export const TotalRrpWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: ${spacing(3)};
`
export const TotalSavingWrapper = styled(TotalRrpWrapper)``

export const SubtotalWrapper = styled.div`
  margin-top: ${spacing(3)};
`

export const BasketSubtotal = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin: ${spacing(3)} 0px;

  ${(props) => mq(props.theme.breakpointUtils.map, 'sm')} {
    margin-bottom: 0;
  }
`

export const SubtotalLabel = styled.span`
  ${Text('bodyText', 'alternate')};
  margin: auto 0;
`
export const TotalRrpLabel = styled.span`
  ${Text('bodyText', 'alternate')};
  margin: auto 0;
`
export const TotalSavingLabel = styled(TotalRrpLabel)``

export const SubtotalValue = styled.span<{ textFont?: TextEntry }>`
  ${(props) => Text(props?.textFont || 'bodyText', 'alternate')};
  text-align: right;
  width: 100px;

  ${(props) => mq(props.theme.breakpointUtils.map, 'sm')} {
    width: 70px;
    text-align: right;
  }
`

export const DetailsContainer = styled.div<{ layout?: string }>`
  ${(props) => mq(props.theme.breakpointUtils.map, 'md')} {
    display: flex;
    flex-direction: column;
    align-self: ${(props) =>
      props.layout === 'inline' ? 'flex-start' : 'flex-end'};
    width: ${(props) => (props.layout === 'inline' ? '395px' : '505px')};
    margin-left: auto;
  }
`

export const SeparatorWrapper = styled.div`
  margin-top: ${spacing(3)};
  width: 100%;
`

export const BottomButtonsWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

export const LiveChatWrapper = styled.div`
  width: 100%;
`

export const StyledTable = styled(Table)`
  flex: 1;
`

export const LiveChatTagWrapper = styled.div`
  display: none;
  position: absolute;
  width: 1px;
  height: 1px;
  overflow: hidden;
  clip: rect(0 0 0 0);

  .LPMcontainer.LPMoverlay {
    display: none !important;
  }
`

export const TableWrapper = styled.div<{ layout?: string }>`
  display: flex;
  flex-direction: column;

  ${(props) => mq(props.theme.breakpointUtils.map, 'lg')} {
    flex-direction: ${(props) =>
      props.layout === 'inline' ? 'row' : 'column'};
    gap: ${spacing(4)};
  }
`

export const SelectYourSampleWrapper = styled.div`
  margin: ${spacing(4)} 0;

  ${(props) => mq(props.theme.breakpointUtils.map, 'lg')} {
    height: 0;
    overflow: hidden;
    margin: 0;
    display: none;
  }
`

export const StyledSelectYourSample = styled(SelectYourSample)`
  :not(:last-child) {
    margin-bottom: ${spacing(2)};
  }
`

export const StyledLoyaltyPoints = styled(LoyaltyPoints)`
  margin-top: ${spacing(3)};
`

export const DeliveryMessage = styled.div`
  ${Text('small', 'alternate')};
  margin-bottom: ${spacing(1)};
  color: ${(props) => props.theme.colors.palette.greys.dark};

  ${(props) => mq(props.theme.breakpointUtils.map, 'sm')} {
    margin-bottom: ${spacing(2)};
  }
`

export const StyledSummaryTitle = styled.h2`
  ${Text('large1', 'alternate')};
  margin-top: ${spacing(3)};
`

export const StandardPrice = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: ${spacing(3)};
`

export const StandardPriceLabel = styled.span`
  ${Text('medium1', 'alternate')};
  margin: auto 0;
`

export const StandardPriceValue = styled.span`
  ${Text('bodyText', 'alternate')};
  text-align: right;
  width: 100px;

  ${(props) => mq(props.theme.breakpointUtils.map, 'sm')} {
    width: 70px;
    text-align: right;
  }
`
export const TotalRrpValue = styled.span`
  ${Text('bodyText', 'alternate')};
  text-align: right;
  width: 100px;

  ${(props) => mq(props.theme.breakpointUtils.map, 'sm')} {
    width: 70px;
    text-align: right;
  }
`
export const TotalSavingValue = styled(TotalRrpValue)`
  color: ${(props) => props.theme.colors.success.base};
`

export const StyledDiscount = styled(Discount)``
