import * as React from 'react'

import {
  EnterpriseContext,
  i18n,
  useCheckoutStart,
} from '@thg-commerce/enterprise-core'
import { pushToDataLayer } from '@thg-commerce/enterprise-metrics/src/data_layer'
import { BasketData } from '@thg-commerce/enterprise-network/src/ApolloProvider/resolvers/Query/Basket/Basket'
import { CloseEventType } from '@thg-commerce/gravity-elements/Modal'
import { ConfirmationModal } from '@thg-commerce/gravity-patterns'

export interface ConfirmationModalPresenterProps {
  open: boolean
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
  basket: BasketData
}

export const ConfirmationModalPresenter = (
  props: ConfirmationModalPresenterProps,
) => {
  React.useEffect(() => {
    if (props.open) {
      pushToDataLayer({
        type: 'elysiumEvent',
        eventData: {
          eventAction: 'Modal Shown',
          eventCategory: 'qualifiedFreeGiftModal',
        },
      })
    }
  }, [props.open])

  const selectedItemsTotal =
    props.basket.selectYourSample.reduce((acc, sample) => {
      return acc + sample.tiers[0].selectedProducts.length
    }, 0) ?? 0

  const maxSelectedItemsTotal =
    props.basket.selectYourSample.reduce((acc, sample) => {
      return acc + sample.tiers[0].maxSelectedProducts
    }, 0) ?? 0

  const { execute: executeCheckoutStart } = useCheckoutStart()

  const { headerHeightRef } = React.useContext(EnterpriseContext)

  if (typeof window === 'undefined') {
    return null
  }

  const i18nText = {
    heading: i18n('basket.selectyoursample.confirmation.modal.heading.text'),
    subtitle: i18n('basket.selectyoursample.confirmation.modal.subtitle.text', [
      selectedItemsTotal.toString(),
      maxSelectedItemsTotal.toString(),
    ]),
    selectYourFreeGifts: i18n(
      'basket.selectyoursample.confirmation.modal.cancel.text',
    ),
    continueToCheckout: i18n(
      'basket.selectyoursample.confirmation.modal.confirm.text',
    ),
  }

  const onCloseEventCallback = (eventType?: CloseEventType) => {
    eventType === CloseEventType.CLOSE_BUTTON &&
      pushToDataLayer({
        type: 'elysiumEvent',
        eventData: {
          eventAction: 'Clicked on X to Close Modal',
          eventCategory: 'qualifiedFreeGiftModal',
        },
      })

    eventType === CloseEventType.UNDERLAY &&
      pushToDataLayer({
        type: 'elysiumEvent',
        eventData: {
          eventAction: 'Clicked on the Modal Underlay',
          eventCategory: 'qualifiedFreeGiftModal',
        },
      })
  }

  const {
    value: [getHeaderHeight],
  } = headerHeightRef

  const modalProps = {
    open: props.open,
    onClose: () => {
      props.setOpen(false)
    },
    i18nText: {
      closeAriaLabel: i18n('general.modal.close.button.arialabel'),
      closeLabel: i18n('general.modal.close.button.label'),
    },
    showHeader: true,
    stickyHeader: true,
    headerOffset: getHeaderHeight(),
    gridColSpan: [12, 10, 8, 6],
    'data-testid': 'sys-confirmation-modal',
    isAutoHeightMobile: true,
    onCloseEventEmitter: onCloseEventCallback,
  }

  return (
    <ConfirmationModal
      heading={i18nText.heading}
      subtitle={i18nText.subtitle}
      modal={modalProps}
      confirmButton={{
        text: i18nText.continueToCheckout,
        onClick: () => {
          pushToDataLayer({
            type: 'elysiumEvent',
            eventData: {
              eventAction: 'Clicked to Checkout in modal',
              eventCategory: 'qualifiedFreeGiftModal',
            },
          })
          executeCheckoutStart()
        },
      }}
      cancelButton={{
        text: i18nText.selectYourFreeGifts,
        onClick: () => {
          pushToDataLayer({
            type: 'elysiumEvent',
            eventData: {
              eventAction: 'Clicked to Select Free Gift in modal',
              eventCategory: 'qualifiedFreeGiftModal',
            },
          })
          props.setOpen(false)
        },
      }}
    />
  )
}
