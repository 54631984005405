import * as React from 'react'
import Head from 'next/head'

import { EmbedWrapper } from './style'
import { GenericEmbedProps } from './types'

export const Instagram = (props: GenericEmbedProps) => {
  return (
    <React.Fragment>
      <Head>
        <script
          src="//www.instagram.com/embed.js"
          key="instagram-embed"
          id="instagram-embed"
          async
        ></script>
      </Head>
      <EmbedWrapper>
        <blockquote
          className="instagram-media"
          data-instgrm-permalink={props.url}
          data-instgrm-version="13"
          style={{
            backgroundColor: '#FFF',
            border: 0,
            borderRadius: '3px',
            boxShadow:
              '0 0 1px 0 rgba(0,0,0,0.5),0 1px 10px 0 rgba(0,0,0,0.15)',
            margin: '1px',
            padding: 0,
            maxWidth: '540px',
            minWidth: '326px',
            width: '100%',
          }}
        ></blockquote>
      </EmbedWrapper>
    </React.Fragment>
  )
}
