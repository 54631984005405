import {
  ProductRecommendationsProductBlockListData,
  ProductRecommendationsProductBlockListVariables,
} from '@thg-commerce/enterprise-network/src/ApolloProvider/resolvers/Query/ProductRecommendationsProductBlockList'

import { FallbackDataLoader, RecommendationSourceType } from '../../types'
import { ProductRecommendationsProductBlockList } from './ProductRecommendationsProductBlockList.graphql'

export const loadProductRecommendations: FallbackDataLoader = async (
  source,
  apolloClient,
) => {
  if (source.type !== RecommendationSourceType.PRODUCT_PAGE) {
    return []
  }

  const { data } = await apolloClient.query<
    {
      productRecommendationsProductBlockList: ProductRecommendationsProductBlockListData | null
    },
    ProductRecommendationsProductBlockListVariables
  >({
    query: ProductRecommendationsProductBlockList,
    variables: {
      sku: source.sku,
      shippingDestination: source.shippingDestination,
      currency: source.currency,
      recommendationsType: source.recommendationsType,
    },
  })

  const products = data?.productRecommendationsProductBlockList?.map(
    (product) => {
      return {
        ...product,
        inStock: product.variants.some((variant) => variant.inStock),
      }
    },
  )

  return products ?? []
}
