import axios from 'axios'

import { createStore } from '@thg-commerce/enterprise-cache'

import { httpsAgent } from '../../httpsAgent'

const NAVIGATION_SERVICE_BASE_URL =
  process.env.NAVIGATION_SERVICE_BASE_URL ||
  'http://gb1.navigation-service-fe.io.thehut.local'

const NAVIGATION_SERVICE_CAMPAIGNS_PATH =
  '/campaigns/site/:site/subsite/:subsite'
const NAVIGATION_SERVCE_BROWSER_SECTION_ID_PATH =
  '/navigation/browserSection/site/:siteId/section/:sectionId'
const REQUEST_TIMEOUT_MILLISECONDS = parseInt(
  process.env.CAMPAIGN_REQUEST_TIMEOUT_MILLISECONDS || '500',
  10,
)

const GLOBAL_WIDGET_PATHS = [
  '/site-widgets',
  '/site-widgets/stripbanner',
  '/site-widgets/emailreengagementmodal',
]

enum SegmentOptionName {
  LOCATION = 'location',
  CUSTOMER_STATE = 'customerState',
  CUSTOMER_LIST = 'customerList',
}

const VARY_HEADER_MAP: { [name in SegmentOptionName]: string } = {
  [SegmentOptionName.LOCATION]: 'X-Customer-Location',
  [SegmentOptionName.CUSTOMER_STATE]: 'X-Customer-State',
  [SegmentOptionName.CUSTOMER_LIST]: 'X-Customer-List',
}

interface BrowserSection {
  subsiteMap: {
    [subsite: string]: {
      fullpath: string
    }
  }
}

interface Configuration {
  headerSectionPath?: string
  paritySubsiteNavigationPath?: string
  footerSectionPath?: string
  concessionCode?: string
}

const getHeaderPath = (config: Configuration) => {
  if (config.headerSectionPath) {
    return `/${config.headerSectionPath}`
  }

  if (config.concessionCode) {
    return `/navigation-header-${config.concessionCode}`
  }

  if (config.paritySubsiteNavigationPath) {
    return `/${config.paritySubsiteNavigationPath}`
  }

  return '/navigation-header'
}

const getFooterPath = (config: Configuration) => {
  if (config.footerSectionPath) {
    return config.footerSectionPath
  }

  return '/responsive-footer-navigation'
}

type CampaignPathIndex = {
  [path: string]: {
    headers: string[]
    startDateTimestamp: number
    endDateTimestamp: number
  }[]
}

const campaignStore = createStore<
  { siteId: number; subsite: string; config: Configuration },
  CampaignPathIndex
>({
  key: (args) => `campaigns_${args.siteId}_${args.subsite}`,
  ttlSeconds: 300,
  staleSeconds: Number.MAX_SAFE_INTEGER,
  forceBackground: true,
  lookup: async (args) => {
    const campaignResponse = await axios.get<{
      campaigns: {
        startTime: number
        endTime: number
        live: boolean
        widgets: { widgetId: number; section: number }[]
        isExperiment: boolean
        targetSegments: {
          segmentKey: SegmentOptionName
          segmentValue: string
        }[]
      }[]
    }>(
      `${NAVIGATION_SERVICE_BASE_URL}${NAVIGATION_SERVICE_CAMPAIGNS_PATH.replace(
        ':site',
        args.siteId.toString(),
      ).replace(':subsite', args.subsite)}`,
      {
        ...(NAVIGATION_SERVICE_BASE_URL.startsWith('https')
          ? { httpsAgent }
          : {}),
        timeout: REQUEST_TIMEOUT_MILLISECONDS,
        headers: { 'X-THG-Client': 'Enterprise', 'user-agent': 'Enterprise' },
      },
    )

    const indexedCampaigns = await campaignResponse.data.campaigns.reduce<
      Promise<CampaignPathIndex>
    >(async (accumulator, campaign) => {
      const campaigns = await accumulator
      if (
        !campaign.live ||
        (campaign.targetSegments.length === 0 && !campaign.isExperiment)
      ) {
        return campaigns
      }

      const initialSegmentHeader = new Set<string>(
        campaign.isExperiment ? ['X-Experiments'] : null,
      )
      const segmentHeaders = campaign.targetSegments.reduce<Set<string>>(
        (segmentAccumulator, segment) => {
          const values = segment.segmentValue.split(',')
          values.forEach((value) => {
            const headerKey = VARY_HEADER_MAP[segment.segmentKey]
            if (segment.segmentKey === SegmentOptionName.CUSTOMER_LIST) {
              segmentAccumulator.add('X-Customer-List')
              segmentAccumulator.add(`${headerKey}-${value}`)
            } else {
              segmentAccumulator.add(headerKey)
            }
          })

          return segmentAccumulator
        },
        initialSegmentHeader,
      )

      const campaignHeaders = {
        headers: Array.from(segmentHeaders),
        startDateTimestamp: campaign.startTime,
        endDateTimestamp: campaign.endTime,
      }

      await Promise.all(
        campaign.widgets.map(async (widget) => {
          const browserSection = await axios.get<BrowserSection>(
            `${NAVIGATION_SERVICE_BASE_URL}${NAVIGATION_SERVCE_BROWSER_SECTION_ID_PATH.replace(
              ':siteId',
              args.siteId.toString(),
            ).replace(':sectionId', widget.section.toString())}`,
            {
              headers: {
                'X-THG-Client': 'Enterprise',
                'user-agent': 'Enterprise',
              },
            },
          )

          const path = browserSection.data.subsiteMap[
            args.subsite
          ].fullpath.split('/')
          path.shift()
          const joinedPath = `/${path.join('/')}`.toLowerCase()

          if (
            GLOBAL_WIDGET_PATHS.includes(joinedPath) ||
            joinedPath === getHeaderPath(args.config)
          ) {
            if (!campaigns['/header']) {
              campaigns['/header'] = []
            }
            campaigns['/header'].push(Object.assign(campaignHeaders))
          } else if (joinedPath === getFooterPath(args.config)) {
            if (!campaigns['/footer']) {
              campaigns['/footer'] = []
            }
            campaigns['/footer'].push(Object.assign(campaignHeaders))
          } else {
            if (!campaigns[`/${path.join('/')}`]) {
              campaigns[`/${path.join('/')}`] = []
            }
            campaigns[`/${path.join('/')}`].push(Object.assign(campaignHeaders))
          }
        }),
      )

      return campaigns
    }, Promise.resolve({}))

    return indexedCampaigns
  },
})

const setAddAll = <T>(set: Set<T>, values: T[]) => {
  for (const value of values) {
    set.add(value)
  }
}

export const campaignVary = async (args: {
  paths: string[]
  siteId: number
  subsite: string
  config: Configuration
}) => {
  try {
    const varySegmentsByPath = await campaignStore.get({
      config: args.config,
      siteId: args.siteId,
      subsite: args.subsite,
    })
    if (!varySegmentsByPath) {
      return []
    }

    const now = new Date().getTime() / 1000

    const headers = args.paths.reduce<Set<string>>((accumulator, path) => {
      if (!varySegmentsByPath[path]) {
        return accumulator
      }

      varySegmentsByPath[path].forEach((segment) => {
        if (
          segment.startDateTimestamp > now ||
          segment.endDateTimestamp < now
        ) {
          return
        }

        setAddAll(accumulator, segment.headers)
      })
      return accumulator
    }, new Set<string>())

    const headerArray = Array.from(headers)

    return headerArray
  } catch (e) {
    if (e instanceof Error) {
      console.warn(`Failed to load page vary headers with error: ${e.message} `)
    } else {
      console.warn(`Failed to load page vary headers with unknown error: ${e} `)
    }

    return []
  }
}
