import * as React from 'react'

import { NewSkeleton as Skeleton } from '@thg-commerce/gravity-elements'
import { mq, spacing, styled } from '@thg-commerce/enterprise-theme'

const MobileContainer = styled(Skeleton)`
  margin-top: ${spacing(3)};
  ${(props) => mq(props.theme.breakpointUtils.map, 'sm')} {
    display: none;
  }
`
const TabletContainer = styled(Skeleton)`
  display: none;

  ${(props) => mq(props.theme.breakpointUtils.map, 'sm')} {
    display: block;
    height: 100px;
    margin-top: ${spacing(3)};
  }

  ${(props) => mq(props.theme.breakpointUtils.map, 'md')} {
    display: none;
  }
`
const DesktopContainer = styled(Skeleton)`
  display: none;

  ${(props) => mq(props.theme.breakpointUtils.map, 'md')} {
    display: block;
    height: 100px;
    margin-top: ${spacing(3)};
  }
`

const MobileItem = () => {
  return (
    <MobileContainer>
      <rect x="0" y="0" rx="3" ry="3" width="73" height="73" />
      <rect x="89" y="0" rx="3" ry="3" width="204" height="18" />
      <rect x="89" y="34" rx="3" ry="3" width="51" height="18" />
      <rect x="89" y="72" rx="3" ry="3" width="144" height="48" />
      <rect
        x="0"
        y="34"
        rx="100"
        ry="100"
        width="24"
        height="24"
        style={{ transform: 'translate(calc(100% - 32px), 0)' }}
      />
      <rect x="0" y="147" rx="3" ry="3" width="100%" height="3" />
    </MobileContainer>
  )
}
const TabletItem = () => {
  return (
    <TabletContainer>
      <rect x="0" y="0" rx="3" ry="3" width="73" height="73" />
      <rect x="100" y="27" rx="3" ry="3" width="35%" height="18" />
      <rect
        x="0"
        y="16"
        rx="3"
        ry="3"
        width="144"
        height="48"
        style={{ transform: 'translate(calc(100% - 311px), 0)' }}
      />
      <rect
        x="0"
        y="27"
        rx="3"
        ry="3"
        width="60"
        height="18"
        style={{ transform: 'translate(calc(100% - 151px), 0)' }}
      />
      <rect
        x="0"
        y="25"
        rx="100"
        ry="100"
        width="24"
        height="24"
        style={{ transform: 'translate(calc(100% - 32px), 0)' }}
      />
      <rect x="0" y="97" rx="3" ry="3" width="100%" height="3" />
    </TabletContainer>
  )
}
const DesktopItem = () => {
  return (
    <DesktopContainer>
      <rect x="0" y="0" rx="3" ry="3" width="73" height="73" />
      <rect x="100" y="27" rx="3" ry="3" width="388" height="18" />
      <rect
        x="0"
        y="27"
        rx="3"
        ry="3"
        width="60"
        height="18"
        style={{ transform: 'translate(calc(100% - 490px), 0)' }}
      />
      <rect
        x="0"
        y="16"
        rx="3"
        ry="3"
        width="144"
        height="48"
        style={{ transform: 'translate(calc(100% - 311px), 0)' }}
      />
      <rect
        x="0"
        y="27"
        rx="3"
        ry="3"
        width="60"
        height="18"
        style={{ transform: 'translate(calc(100% - 151px), 0)' }}
      />
      <rect
        x="0"
        y="25"
        rx="100"
        ry="100"
        width="24"
        height="24"
        style={{ transform: 'translate(calc(100% - 32px), 0)' }}
      />

      <rect x="0" y="97" rx="3" ry="3" width="100%" height="3" />
    </DesktopContainer>
  )
}

export const Items = () => {
  return (
    <React.Fragment>
      <MobileItem />
      <MobileItem />
      <MobileItem />
      <TabletItem />
      <TabletItem />
      <TabletItem />
      <DesktopItem />
      <DesktopItem />
      <DesktopItem />
    </React.Fragment>
  )
}
