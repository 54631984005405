import * as React from 'react'
import { useMutation } from '@apollo/react-hooks'
import gql from 'graphql-tag'

import {
  i18n,
  useBasketId,
  useFormattableI18nProperty,
  useHorizonSessionSettings,
} from '@thg-commerce/enterprise-core'
import { HorizonSessionSettings } from '@thg-commerce/enterprise-core/src/EnterpriseContext/types'
import { VisuallyHidden } from '@thg-commerce/gravity-elements'

import {
  OfferMessage,
  OfferValue,
  OfferWrapper,
  RemoveOffer,
  RemoveOfferWrapper,
  Title,
} from './styles'

interface AppliedOffer {
  message: string
  removeable: boolean
  displayValue?: string
  amount?: string
}

interface OfferSummaryProps {
  appliedOffers: AppliedOffer[]
  className?: string
  enableDiscountPrice?: boolean
  discount: {
    amount: string
    displayValue: string
  }
  discountFromRrpExcludingOffers?: {
    amount: string
    displayValue: string
  }
  basketShowTotalRrpDiscountPerItem?: boolean
}

interface RemoveOfferVariables {
  basketId?: string
  sessionSettings: HorizonSessionSettings
}

export const REMOVE_OFFER_MUTATION = gql`
  mutation RemoveCodeFromBasket(
    $basketId: ID
    $sessionSettings: SessionSettings!
  ) {
    removeCodeFromBasket(
      basketId: $basketId
      sessionSettings: $sessionSettings
    ) @client {
      id
    }
  }
`

export const OfferSummary = (props: OfferSummaryProps) => {
  const i18nText = {
    totalSaving: i18n('basket.discount.summary.title'),
    discountSaving: i18n('basket.discount.saving.text'),
    discountSavingRrp: i18n('basket.discount.saving.rrp.text'),
    removeOfferText: i18n('basket.discount.removeoffer.text'),
    removeOfferLabel: i18n('basket.discount.removeoffer.label'),
    negative: i18n('general.negative.text'),
    rrpDiscountMessage: useFormattableI18nProperty(
      'basket.rrp.discount.message',
    ),
  }

  const [basketId, setBasketId] = useBasketId()
  const sessionSettings = useHorizonSessionSettings()

  const [removeOffer] = useMutation<
    { removeCodeFromBasket: { id: string } },
    RemoveOfferVariables
  >(REMOVE_OFFER_MUTATION, {
    fetchPolicy: 'no-cache',
    onCompleted: (data) => {
      data &&
        data.removeCodeFromBasket &&
        setBasketId(data.removeCodeFromBasket.id)
    },
  })

  const displayOfferAmount = (amount: string): boolean =>
    parseInt(amount, 10) !== 0 || false

  return (
    <div className={props.className} data-testid="basket-offersummary">
      <Title>
        {props.enableDiscountPrice
          ? `${i18nText.discountSaving}:`
          : i18nText.totalSaving}
      </Title>
      {props.enableDiscountPrice &&
        parseInt(props.discountFromRrpExcludingOffers?.amount || '0', 10) >
          0 && (
          <OfferWrapper>
            <OfferMessage content={i18nText.discountSavingRrp} />
            <OfferValue aria-hidden>
              - {props.discountFromRrpExcludingOffers?.displayValue}
            </OfferValue>
            <VisuallyHidden
              text={`${i18nText.negative} ${props.discountFromRrpExcludingOffers?.displayValue}`}
            />
          </OfferWrapper>
        )}
      {props.basketShowTotalRrpDiscountPerItem &&
      props.discount.amount &&
      parseFloat(props.discount.amount) > 0 ? (
        <OfferWrapper>
          <OfferMessage
            content={i18nText.rrpDiscountMessage(props.discount.displayValue)}
          />
          <OfferValue aria-hidden>- {props.discount.displayValue}</OfferValue>
          <VisuallyHidden
            text={`${i18nText.negative} ${props.discount.displayValue}`}
          />
        </OfferWrapper>
      ) : null}
      {props.appliedOffers.map((offer, index) => (
        <OfferWrapper key={index}>
          <OfferMessage content={offer.message} />
          <RemoveOfferWrapper>
            {offer.amount &&
              displayOfferAmount(offer.amount) &&
              !props.basketShowTotalRrpDiscountPerItem && (
                <React.Fragment>
                  <OfferValue aria-hidden>- {offer.displayValue}</OfferValue>
                  <VisuallyHidden
                    text={`${i18nText.negative} ${offer.displayValue}`}
                  />
                </React.Fragment>
              )}
            {offer.removeable && (
              <RemoveOffer
                onClick={() => {
                  removeOffer({
                    variables: {
                      basketId,
                      sessionSettings,
                    },
                  })
                }}
                aria-label={i18nText.removeOfferLabel}
                data-testid="basket-offersummary-remove-offer"
              >
                {i18nText.removeOfferText}
              </RemoveOffer>
            )}
          </RemoveOfferWrapper>
        </OfferWrapper>
      ))}
    </div>
  )
}
