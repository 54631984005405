import { ProductBlockListData } from '@thg-commerce/enterprise-network/src/ApolloProvider/resolvers/Query/Content/ProductBlockList'

import { QubitHomePageRecsWidgetProductBlockList } from './QubitHomePageRecsWidgetProductBlockList.graphql'
import { FallbackDataLoader, RecommendationSourceType } from '../../types'

export const loadQubitHomePageRecsProducts: FallbackDataLoader = async (
  source,
  apolloClient,
) => {
  if (source.type !== RecommendationSourceType.QUBIT_HOMEPAGE_RECS_WIDGET) {
    return []
  }

  const { data } = await apolloClient.query<{
    qubitHomePageRecsWidgetProductBlockList: ProductBlockListData
  }>({
    query: QubitHomePageRecsWidgetProductBlockList,
    variables: {
      path: source.path,
      widgetId: source.widgetId.toString(),
      currency: source.currency,
      shippingDestination: source.shippingDestination,
    },
  })

  const products = data?.qubitHomePageRecsWidgetProductBlockList.map(
    (product) => {
      return {
        ...product,
        inStock: product.variants.some((variant) => variant.inStock),
      }
    },
  )

  return products ?? []
}
