import * as React from 'react'

import {
  PlatformMessage,
  PlatformMessageType,
} from '@thg-commerce/gravity-elements'
import { BasketContext, i18n } from '@thg-commerce/enterprise-core'
import { ApplyCodeToBasketState } from '@thg-commerce/enterprise-core/src/ConfigurationLoader/types'
import { BasketMessageType } from '@thg-commerce/enterprise-network/src/generated/graphql'
import { styled, spacing } from '@thg-commerce/enterprise-theme'

import { upsellMessageTransformer } from './transformers/upsell'
import { referralCodeInsertedTransformer } from './transformers/referralCodeInserted'
import { MessageTransformer } from './types'

export interface MessagesProps {
  className?: string
  messages: {
    type: BasketMessageType
    message?: string
  }[]
}

const MESSAGE_TYPE_MAP: {
  [type in BasketMessageType]?: PlatformMessageType
} = {
  [BasketMessageType.PromoCodeAlreadyUsed]: 'error',
  [BasketMessageType.CodeValidButNotApplicableToBasket]: 'error',
  [BasketMessageType.CodeExpired]: 'error',
  [BasketMessageType.CodeInvalid]: 'error',
  [BasketMessageType.ReferrerNotEligible]: 'error',
  [BasketMessageType.ProductOutOfStock]: 'error',
  // [BasketMessageType.MixedFulfilmentMethods]: 'error',
  [BasketMessageType.ItemsHaveErrors]: 'error',
  [BasketMessageType.Upsell]: 'info',
  [BasketMessageType.BetterOfferAlreadyApplied]: 'error',
  [BasketMessageType.OfferApplied]: 'success',
  [BasketMessageType.SelectYourSampleApplied]: 'success',
  [BasketMessageType.ReferralCodeInserted]: 'success',
}

const StyledPlatformMessage = styled(PlatformMessage)<{ lastMessage: boolean }>`
  ${(props) => !props.lastMessage && `margin-bottom: ${spacing(2)};`}
`

const MESSAGE_TRANSFORMERS: {
  [type in BasketMessageType]?: MessageTransformer
} = {
  [BasketMessageType.OfferApplied]: (i18nText, message) => {
    return `${i18nText.offerAppliedPrefix} ${message}`
  },
  [BasketMessageType.SelectYourSampleApplied]: (i18nText, message) => {
    return `${i18nText.offerAppliedPrefix} ${message} ${i18nText.selectYourSampleSuffix}`
  },
  [BasketMessageType.MixedFulfilmentMethods]: (i18nText, _) => {
    return `${i18nText.mixedFulfilmentMethodsMessage}`
  },
  [BasketMessageType.ItemsHaveErrors]: (i18nText, _) => {
    return `${i18nText.itemsHaveErrorsMessage}`
  },
  [BasketMessageType.Upsell]: upsellMessageTransformer,
  [BasketMessageType.BetterOfferAlreadyApplied]: (i18nText) =>
    i18nText.betterOfferAlreadyApplied,
  [BasketMessageType.ReferralCodeInserted]: referralCodeInsertedTransformer,
}

export const Messages: React.FunctionComponent<MessagesProps> = (props) => {
  const { applyCodeToBasketState } = React.useContext(BasketContext)

  const i18nText = {
    offerAppliedPrefix: i18n('basket.specialoffer.appliedprefix.text'),
    selectYourSampleSuffix: i18n(
      'basket.specialoffer.selectyoursample.suffix.text',
    ),
    mixedFulfilmentMethodsMessage: i18n('basket.mixedfulfilment.message'),
    itemsHaveErrorsMessage: i18n('basket.itemshaveerrors.message'),
    betterOfferAlreadyApplied: i18n(
      'basket.discountapply.betterofferapplied.text',
    ),
    DISCOUNT_CODE_INVALID: i18n('basket.discountapply.invalid.text'),
    DISCOUNT_CODE_EXPIRED: i18n('basket.discountapply.expired.text'),
  }

  const isDiscountCodeMessage =
    applyCodeToBasketState &&
    (applyCodeToBasketState === ApplyCodeToBasketState.DISCOUNT_CODE_EXPIRED ||
      applyCodeToBasketState === ApplyCodeToBasketState.DISCOUNT_CODE_INVALID)

  return (
    <div className={props.className} data-testid="basket-messages">
      {isDiscountCodeMessage ? (
        <StyledPlatformMessage
          type="error"
          text={applyCodeToBasketState && i18nText[applyCodeToBasketState]}
          data-testid="discount-message"
          lastMessage={props.messages.length === 0}
        />
      ) : null}
      {props.messages.map((message, index) => {
        if (!MESSAGE_TYPE_MAP[message.type]) {
          return null
        }

        const TransformerComponent = MESSAGE_TRANSFORMERS[message.type]?.(
          i18nText,
          message.message || '',
        )

        const messageText =
          TransformerComponent && typeof TransformerComponent !== 'string' ? (
            <TransformerComponent
              i18nText={i18nText}
              message={message.message || ''}
              StyledPlatformMessage={StyledPlatformMessage}
            />
          ) : typeof TransformerComponent === 'string' ? (
            TransformerComponent
          ) : (
            message.message
          )

        if (React.isValidElement(messageText)) {
          return (
            <React.Fragment key={`basket-message-${index}`}>
              {messageText}
            </React.Fragment>
          )
        }

        if (typeof messageText !== 'string') {
          return null
        }

        return (
          <StyledPlatformMessage
            text={messageText}
            type={MESSAGE_TYPE_MAP[message.type] || 'info'}
            lastMessage={index === props.messages.length - 1}
            key={`basket-message-${index}`}
          />
        )
      })}
    </div>
  )
}
