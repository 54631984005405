import * as React from 'react'

import { LandingPageContext } from './LandingPageContext'

export interface LandingPageContextProviderProps {
  categories: string[]
}

export const LandingPageContextProvider: React.FunctionComponent<LandingPageContextProviderProps> = (
  props,
) => {
  return (
    <LandingPageContext.Provider
      value={{
        categories: props.categories,
      }}
    >
      {props.children}
    </LandingPageContext.Provider>
  )
}
