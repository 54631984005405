import * as React from 'react'

import { BasketContext } from '@thg-commerce/enterprise-core'
import { ApplyCodeToBasketState } from '@thg-commerce/enterprise-core/src/ConfigurationLoader/types'

import { MessageTransformer } from '../types'

export const referralCodeInsertedTransformer: MessageTransformer = () => ({
  message,
  StyledPlatformMessage,
}) => {
  const { applyCodeToBasketState } = React.useContext(BasketContext)

  const isReferralCodeMessagePresent =
    applyCodeToBasketState &&
    (applyCodeToBasketState === ApplyCodeToBasketState.REFERRAL_CODE_APPLIED ||
      applyCodeToBasketState === ApplyCodeToBasketState.REFERRAL_CODE_INVALID ||
      applyCodeToBasketState === ApplyCodeToBasketState.NOT_LOGGED_IN ||
      applyCodeToBasketState === ApplyCodeToBasketState.REFERRER_NOT_ELIGIBLE)

  if (isReferralCodeMessagePresent) return null

  return <StyledPlatformMessage type="success" text={message || ''} />
}
