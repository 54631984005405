import * as React from 'react'

import {
  ButtonEmphases,
  Modal,
  ModalProps,
} from '@thg-commerce/gravity-elements'

import {
  CancelButton,
  ConfirmButton,
  CtaWrapper,
  Subtitle,
  Title,
} from './styles'

export interface ConfirmationModalProps {
  heading: string
  subtitle: string | JSX.Element
  children?: React.ReactNode
  confirmButton: {
    text: string
    onClick: () => void
    emphasis?: ButtonEmphases
  }
  cancelButton?: {
    text: string
    onClick: () => void
    emphasis?: ButtonEmphases
  }
  modal: Omit<ModalProps, 'children'>
  fullWidthButtons?: boolean
}

export const ConfirmationModal = (props: ConfirmationModalProps) => {
  return (
    <Modal {...props.modal}>
      <Title>{props.heading}</Title>
      <Subtitle>{props.subtitle}</Subtitle>
      {props.children && props.children}
      <CtaWrapper fullWidthButtons={props.fullWidthButtons}>
        {props.cancelButton && (
          <CancelButton
            data-testid="cancel-button"
            onClick={props.cancelButton.onClick}
            emphasis={
              props.cancelButton.emphasis && props.cancelButton.emphasis
            }
          >
            {props.cancelButton.text}
          </CancelButton>
        )}
        <ConfirmButton
          data-testid="confirm-button"
          onClick={props.confirmButton.onClick}
          emphasis={
            props.confirmButton.emphasis
              ? props.confirmButton.emphasis
              : 'medium'
          }
        >
          {props.confirmButton.text}
        </ConfirmButton>
      </CtaWrapper>
    </Modal>
  )
}
