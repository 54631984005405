import * as React from 'react'

import loadable from '@loadable/component'
import gql from 'graphql-tag'

import { useMutation } from '@apollo/react-hooks'

import {
  BasketItemMessage,
  BasketItemMessageType,
  FulfilmentMethod,
  InStockLocation,
} from '@thg-commerce/enterprise-network/src/generated/graphql'
import {
  ChangeFulfilmentTypeData,
  ChangeFulfilmentTypeVariables,
} from '@thg-commerce/enterprise-network/src/ApolloProvider/resolvers/Mutation/ChangeFulfilmentType'
import { Button } from '@thg-commerce/gravity-elements'
import {
  BasketContext,
  useBasketId,
  useHorizonSessionSettings,
} from '@thg-commerce/enterprise-core'
import { spacing, useTheme } from '@thg-commerce/enterprise-theme'

import {
  ClickAndCollectButtonContainer,
  ClickAndCollectContainer,
  CollectInStore,
  FulfilmentMethodText,
  StoreName,
} from '../styles'

const ClickAndCollectIcon = loadable(
  () =>
    import('@thg-commerce/gravity-icons/src/components/ClickAndCollectSuccess'),
  { ssr: true, fallback: <div style={{ width: 20, height: 20 }} /> },
)

const DeliveryIconSuccess = loadable(
  () => import('@thg-commerce/gravity-icons/src/components/DeliverySuccess'),
  { ssr: true, fallback: <div style={{ width: 20, height: 20 }} /> },
)

export const CHANGE_PRODUCT_FULFILMENT_MUTATION = gql`
  mutation ChangeFulfilmentType(
    $basketId: ID
    $itemId: ID!
    $fulfilmentInput: BasketAddFulfilmentInput!
    $settings: SessionSettings!
  ) {
    changeFulfilmentType(
      basketId: $basketId
      itemId: $itemId
      fulfilmentInput: $fulfilmentInput
      settings: $settings
    ) {
      id
      items {
        fulfilmentMethod
      }
    }
  }
`

export enum WeightGroupsType {
  OneMan = '1-Man',
  OutOfGauge = 'out-of-gauge',
  VendorDirect = 'vendor-direct',
}

interface FulfilmentMethodsMessageProps {
  messages: BasketItemMessage[]
  availableFulfilmentMethods: FulfilmentMethod[]
  fulfilmentMethod: FulfilmentMethod
  itemId: string
  i18nText: {
    basketFulfilmentChangeStore: string
    basketFulfilmentChangeStoreAriaLabel: string
    basketFulfilmentChangeHomeDelivery: string
    basketFulfilmentChangeHomeDeliveryAriaLabel: string
    basketFulfilmentNamedDayDelivery: string
    basketFulfilmentNamedDayDeliveryOptions: string
    basketFulfilmentNextDayDelivery: string
    basketFulfilmentNextDayDeliveryOptions: string
    basketFulfilmentCollectInStore: string
    basketFulfilmentDeliverToStore: string
    basketFulfilmentCollectInStoreStock: (_: string) => string
    basketFulfilmentCollectInStoreOutOfStock: string
    basketFulfilmentChangeCollectInStore: string
    basketFulfilmentLeadTime: string
    dynamicDeliveryText: {
      homeDelivery: {
        isAvailable: string
        isNotAvailable: string
        isOutOfStock: string
        datedDelivery?: string
        nextDayDelivery?: string
        oneManDelivery?: string
        outOfGaugeDelivery?: string
        dynamicDelivery?: string
      }
    }
  }
  presentClickAndCollectModalProps: {
    sku: string
    image: string
    title: string
    isProductPage: boolean
    quantity: number
  }
  storeName?: string
  storeStock?: number
  inStock?: boolean
  isCheckStock?: boolean
  isOrderInStore?: boolean
  leadTime?: number | null
  weightGroups?: string[]
  isBookable?: boolean | null
  inStockLocations?: InStockLocation[]
}

export const FulfilmentMethodsMessage = (
  props: FulfilmentMethodsMessageProps,
) => {
  const outOfGauge = props.weightGroups?.includes(WeightGroupsType.OutOfGauge)

  const eligibleForHomeDelivery =
    props.availableFulfilmentMethods.includes(FulfilmentMethod.HomeDelivery) &&
    props.inStockLocations?.includes(InStockLocation.Warehouse)

  const eligibleForDatedDelivery =
    props.leadTime &&
    props.weightGroups?.includes(WeightGroupsType.VendorDirect)

  const eligibleForNextDayDelivery =
    (outOfGauge || props.weightGroups?.includes(WeightGroupsType.OneMan)) &&
    props.isBookable

  const noHomeDelivery = !props.availableFulfilmentMethods.includes(
    FulfilmentMethod.HomeDelivery,
  )

  const theme = useTheme()

  const homeDeliveryMessage = () => {
    if (eligibleForHomeDelivery) {
      if (eligibleForDatedDelivery) {
        return props.i18nText.dynamicDeliveryText?.homeDelivery.datedDelivery
      }
      if (eligibleForNextDayDelivery) {
        if (outOfGauge) {
          return props.i18nText.dynamicDeliveryText?.homeDelivery
            .outOfGaugeDelivery
        }
        return props.i18nText.dynamicDeliveryText?.homeDelivery.oneManDelivery
      }

      return props.i18nText.dynamicDeliveryText?.homeDelivery.dynamicDelivery
    }

    if (noHomeDelivery) {
      return props.i18nText.dynamicDeliveryText?.homeDelivery.isNotAvailable
    }
    return props.i18nText.dynamicDeliveryText?.homeDelivery.isOutOfStock
  }

  const { presentClickAndCollectModal } = React.useContext(BasketContext)
  const [basketId, setBasketId] = useBasketId()
  const sessionSettings = useHorizonSessionSettings()

  const [changeFulfilmentMethod] = useMutation<
    { changeFulfilmentType: ChangeFulfilmentTypeData },
    ChangeFulfilmentTypeVariables
  >(CHANGE_PRODUCT_FULFILMENT_MUTATION, {
    fetchPolicy: 'no-cache',
    onCompleted: (data) => {
      if (data && data.changeFulfilmentType) {
        setBasketId(data.changeFulfilmentType.id)
      }
    },
  })

  const availableForHomeDelivery =
    props.messages.length > 0 &&
    props.messages.filter(
      (message) =>
        message.type !== 'HOME_DELIVERY_OUT_OF_STOCK' &&
        message.type !== 'FULFILMENT_ISSUE',
    ).length > 0

  switch (props.fulfilmentMethod) {
    case FulfilmentMethod.CollectInStore:
    case FulfilmentMethod.DeliverToStore:
      return (
        <ClickAndCollectContainer>
          <CollectInStore>
            <ClickAndCollectIcon width={36} />
            <div>
              <FulfilmentMethodText>
                {props.fulfilmentMethod === FulfilmentMethod.CollectInStore
                  ? props.i18nText.basketFulfilmentCollectInStore
                  : props.i18nText.basketFulfilmentDeliverToStore}
              </FulfilmentMethodText>
              <StoreName>
                {props.storeName}
                <p style={{ marginLeft: spacing(1), marginRight: spacing(1) }}>
                  {props.storeStock
                    ? props.i18nText.basketFulfilmentCollectInStoreStock(
                        props.storeStock.toString(),
                      )
                    : props.i18nText.basketFulfilmentCollectInStoreOutOfStock}
                </p>
              </StoreName>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'flex-start',
                }}
              >
                <Button
                  aria-label={
                    props.i18nText.basketFulfilmentChangeStoreAriaLabel
                  }
                  data-testid="fulfilment-click-and-collect-button"
                  type="button"
                  emphasis="low"
                  sizing="regular"
                  style={{ paddingLeft: 0, fontWeight: 'bold' }}
                  onClick={() =>
                    presentClickAndCollectModal?.current(
                      props.presentClickAndCollectModalProps.sku,
                      props.presentClickAndCollectModalProps.image,
                      props.presentClickAndCollectModalProps.title,
                      props.presentClickAndCollectModalProps.isProductPage,
                      props.presentClickAndCollectModalProps.quantity,
                      props.itemId,
                    )
                  }
                >
                  {props.i18nText.basketFulfilmentChangeStore}
                </Button>
              </div>
            </div>
          </CollectInStore>
          {props.availableFulfilmentMethods.includes(
            FulfilmentMethod.HomeDelivery,
          ) && (
            <Button
              aria-label={
                props.i18nText.basketFulfilmentChangeHomeDeliveryAriaLabel
              }
              style={{
                paddingLeft: 0,
                fontWeight: 'bold',
                textAlign: 'inherit',
              }}
              className="change-home-delivery-btn"
              emphasis="low"
              type="button"
              onClick={() => {
                changeFulfilmentMethod({
                  variables: {
                    basketId,
                    fulfilmentInput: {
                      method: FulfilmentMethod.HomeDelivery,
                    },
                    itemId: props.itemId,
                    settings: sessionSettings,
                  },
                })
              }}
            >
              {props.i18nText.basketFulfilmentChangeHomeDelivery}
            </Button>
          )}
        </ClickAndCollectContainer>
      )
    case FulfilmentMethod.HomeDelivery:
      return (
        <ClickAndCollectContainer>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              padding: spacing(1),
            }}
          >
            <DeliveryIconSuccess
              fill={
                availableForHomeDelivery
                  ? theme.colors.success.base
                  : theme.colors.palette.greys.light
              }
            />
            {props.messages[0].type ===
            BasketItemMessageType.HomeDeliveryNamedDay ? (
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <FulfilmentMethodText>
                  {props.i18nText.basketFulfilmentNamedDayDelivery}
                </FulfilmentMethodText>
                <FulfilmentMethodText>
                  {props.i18nText.basketFulfilmentNamedDayDeliveryOptions}
                </FulfilmentMethodText>
              </div>
            ) : props.messages[0].type ===
              BasketItemMessageType.HomeDeliveryNextDay ? (
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <FulfilmentMethodText>
                  {props.i18nText.basketFulfilmentNextDayDelivery}
                </FulfilmentMethodText>
                <FulfilmentMethodText>
                  {props.i18nText.basketFulfilmentNextDayDeliveryOptions}
                </FulfilmentMethodText>
              </div>
            ) : (
              <FulfilmentMethodText>
                {homeDeliveryMessage()}
              </FulfilmentMethodText>
            )}
          </div>

          {props.availableFulfilmentMethods.includes(
            FulfilmentMethod.CollectInStore,
          ) && (
            <ClickAndCollectButtonContainer data-testid="click-and-collect-button">
              <Button
                aria-label={props.i18nText.basketFulfilmentChangeStoreAriaLabel}
                emphasis="low"
                onClick={() =>
                  presentClickAndCollectModal?.current(
                    props.presentClickAndCollectModalProps.sku,
                    props.presentClickAndCollectModalProps.image,
                    props.presentClickAndCollectModalProps.title,
                    props.presentClickAndCollectModalProps.isProductPage,
                    props.presentClickAndCollectModalProps.quantity,
                    props.itemId,
                  )
                }
              >
                {props.i18nText.basketFulfilmentChangeCollectInStore}
              </Button>
            </ClickAndCollectButtonContainer>
          )}
        </ClickAndCollectContainer>
      )
    default:
      return null
  }
}
