import * as React from 'react'

import { ProductPageData } from '@thg-commerce/enterprise-network/src/ApolloProvider/resolvers/Query/Product/ProductPage'
import {
  ProductImage,
  ProductContentItem,
} from '@thg-commerce/enterprise-network/src/generated/graphql'

export interface Product {
  title: string
  sku: number
  images: ProductImage[]
  content: ProductContentItem[]
  inStock: boolean
  descriptionContent: ProductPageData['description']
}
export const SelectYourSampleContext = React.createContext<{
  selectYourSampleInteracted: boolean
  setSelectYourSampleInteracted: React.Dispatch<React.SetStateAction<boolean>>
  informationModalPresenter: React.MutableRefObject<
    ((product: Product) => void | undefined) | undefined
  >
}>({
  selectYourSampleInteracted: false,
  setSelectYourSampleInteracted: () => {},
  informationModalPresenter: { current: undefined },
})
