import React from 'react'
import loadable from '@loadable/component'

import { styled, Text, spacing } from '@thg-commerce/gravity-theme'
import { Button } from '@thg-commerce/gravity-elements'
import { BasketContext, i18n } from '@thg-commerce/enterprise-core'

const IconInfo = loadable(
  () => import('@thg-commerce/gravity-icons/src/components/IconInfo'),
  { ssr: true, fallback: <div style={{ width: 24, height: 24 }} /> },
)

const StyledIconInfo = styled(IconInfo)`
  path {
    fill: ${(props) => props.theme.colors.palette.brand.base};
  }
`

const ClickAndCollectContainer = styled.div`
  display: flex;
  gap: ${spacing(2)};
  margin-bottom: ${spacing(3)};
`

const Separator = styled.hr`
  border: 0;
  border-top: 1px solid ${(props) => props.theme.colors.palette.greys.light};
  margin-bottom: ${spacing(3)};
`

const Wrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  gap: ${spacing(1)};
`

const IconWrapper = styled.div`
  min-width: 20px;
`

const Title = styled.span`
  ${Text('bodyText', 'alternate')}
  margin-top: -${spacing(0.25)};
`

const ClickAndCollectButton = styled(Button)`
  text-align: initial;
`

export const ClickAndCollectInfoModalButton = () => {
  const { presentClickAndCollectInfoModal } = React.useContext(BasketContext)
  const i18nText = {
    buttonText: i18n('basket.clickandcollect.modal.trigger'),
    title: i18n('basket.clickandcollect.information.title'),
  }

  return (
    <React.Fragment>
      <Separator />
      <ClickAndCollectContainer data-testid="click-and-collect-info-modal-container">
        <IconWrapper>
          <StyledIconInfo />
        </IconWrapper>
        <Wrapper>
          <Title>{i18nText.title}</Title>
          <ClickAndCollectButton
            emphasis="low"
            onClick={() => presentClickAndCollectInfoModal?.current()}
          >
            {i18nText.buttonText}
          </ClickAndCollectButton>
        </Wrapper>
      </ClickAndCollectContainer>
      <Separator />
    </React.Fragment>
  )
}
