import React from 'react'

import loadable from '@loadable/component'

import { styled, Text, spacing } from '@thg-commerce/gravity-theme'
import { Button, SafeHtml } from '@thg-commerce/gravity-elements'
export interface ClickAndCollectInfoModalProps {
  i18nText: {
    modalTitle: string
    modalContent: string
    closeButtonText: string
  }
  onClick: () => void
}

const IconInfo = loadable(
  () => import('@thg-commerce/gravity-icons/src/components/IconInfo'),
  { ssr: true, fallback: <div style={{ width: 24, height: 24 }} /> },
)

const StyledIconInfo = styled(IconInfo)`
  path {
    fill: ${(props) => props.theme.colors.palette.brand.base};
  }
  position: absolute;
  top: 5px;
`
const IconWrapper = styled.div`
  position: relative;
  min-width: 20px;
  margin-right: ${spacing(1)};
`

const ModalWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

const TextWrapper = styled.div`
  padding-inline: ${spacing(2)};
`

const ModalTitle = styled.h1`
  ${Text('large2', 'alternate')};
  margin-bottom: ${spacing(2)};
`

const StyledSafeHtml = styled(SafeHtml)`
  margin-bottom: ${spacing(4)};

  strong {
    font-weight: bold;
  }
`

const ModalButton = styled(Button)`
  width: 100%;
`

export const ClickAndCollectInfoModal = ({
  i18nText,
  onClick,
}: ClickAndCollectInfoModalProps) => {
  return (
    <ModalWrapper>
      <TextWrapper>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <IconWrapper>
            <StyledIconInfo />
          </IconWrapper>
          <ModalTitle>{i18nText.modalTitle}</ModalTitle>
        </div>
        <StyledSafeHtml
          content={i18nText.modalContent}
          __dangerouslyAllowedTags={['b']}
        />
      </TextWrapper>
      <ModalButton
        emphasis="high"
        onClick={onClick}
        data-testid="click-and-collect-info-modal-button"
      >
        {i18nText.closeButtonText}
      </ModalButton>
    </ModalWrapper>
  )
}
