import * as React from 'react'

import { styled, mq } from '@thg-commerce/enterprise-theme'
import { NewSkeleton as Skeleton } from '@thg-commerce/gravity-elements'

const MobileHeadingContainer = styled(Skeleton)`
  height: 104px;
  ${(props) => mq(props.theme.breakpointUtils.map, 'sm')} {
    display: none;
  }
`

const TabletHeadingContainer = styled(Skeleton)`
  display: none;

  ${(props) => mq(props.theme.breakpointUtils.map, 'sm')} {
    display: block;
    height: 48px;
  }

  ${(props) => mq(props.theme.breakpointUtils.map, 'md')} {
    display: none;
  }
`

const DesktopHeadingContainer = styled(Skeleton)`
  display: none;
  ${(props) => mq(props.theme.breakpointUtils.map, 'md')} {
    display: block;
    height: 96px;
  }
`

const MobileHeading = () => {
  return (
    <MobileHeadingContainer>
      <rect x="0" y="0" rx="3" ry="3" width="172" height="32" />
      <rect x="0" y="56" rx="3" ry="3" width="100%" height="48" />
    </MobileHeadingContainer>
  )
}

const TabletHeading = () => {
  return (
    <TabletHeadingContainer>
      <rect x="0" y="0" rx="3" ry="3" width="172" height="32" />
      <rect
        x="0"
        y="0"
        rx="3"
        ry="3"
        width="277"
        height="48"
        style={{ transform: 'translate(calc(100% - 277px), 0)' }}
      />
    </TabletHeadingContainer>
  )
}

const DesktopHeading = () => {
  return (
    <DesktopHeadingContainer>
      <rect x="0" y="0" rx="3" ry="3" width="334" height="32" />
      <rect
        x="0"
        y="0"
        rx="3"
        ry="3"
        width="277"
        height="48"
        style={{ transform: 'translate(calc(100% - 277px), 0)' }}
      />
    </DesktopHeadingContainer>
  )
}

export const Heading = () => {
  return (
    <React.Fragment>
      <MobileHeading />
      <TabletHeading />
      <DesktopHeading />
    </React.Fragment>
  )
}
