import * as React from 'react'

export interface LandingPageContextInterface {
  categories: string[]
}

export const LandingPageContext = React.createContext<
  LandingPageContextInterface
>({
  categories: [],
})
