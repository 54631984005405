import React from 'react'

import {
  BasketContext,
  EnterpriseContext,
  i18n,
  useLogger,
} from '@thg-commerce/enterprise-core'
import { ModalRenderer } from '@thg-commerce/enterprise-modal'

import { useScrollLock } from '@thg-commerce/gravity-system'

import { ClickAndCollectInfoModal } from '../ClickAndCollectInfoModal/'

export const ClickAndCollectInfoModalPresenter = () => {
  const logger = useLogger()
  const basketContext = React.useContext(BasketContext)
  const { headerHeight } = React.useContext(EnterpriseContext)
  const [open, setOpen] = React.useState(false)
  const setScrollLock = useScrollLock()
  const modalI18nText = {
    modalTitle: i18n('basket.clickandcollect.information.title'),
    modalContent: `${i18n(
      'basket.clickandcollect.information.content1',
    )} <b>${i18n('basket.clickandcollect.information.content2')}</b> ${i18n(
      'basket.clickandcollect.information.content3',
    )} <b>${i18n('basket.clickandcollect.information.content4')}</b>`,
    closeButtonText: i18n('general.modal.close.button.label'),
  }

  const modalCloseI18nText = {
    closeAriaLabel: i18n('general.modal.close.button.arialabel'),
    closeLabel: i18n('general.modal.close.button.label'),
  }

  if (!basketContext.presentClickAndCollectInfoModal) {
    logger.warn(
      'ClickAndCollectInfoModalPresenter: Failed to find an initialised BasketContext. Make sure presentClickAndCollectInfoModal ref is defined and it wraps ClickAndCollectInfoModalPresenter.',
    )
    return null
  }

  basketContext.presentClickAndCollectInfoModal.current = () => {
    setOpen(true)
  }

  if (!open) {
    return null
  }

  return (
    <ModalRenderer
      showHeader
      stickyHeader
      verticalAlignment="center"
      renderContent={() => (
        <ClickAndCollectInfoModal
          i18nText={modalI18nText}
          onClick={() => {
            setOpen(false)
            typeof document !== 'undefined' &&
              typeof window !== 'undefined' &&
              setScrollLock(false, document, window)
          }}
        />
      )}
      gridColSpan={[12, 10, 8, 6]}
      headerOffset={headerHeight}
      contentPadding="0 16px 32px 16px"
      closeI18nText={modalCloseI18nText}
      data-testid="click-and-collect-info-modal"
      onClose={() => setOpen(false)}
      isOpen={open}
      gridRowStart={[-1, 2, 2, 2]}
      gridRowSpan={[6, 10, 10, 10]}
    />
  )
}
