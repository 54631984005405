import * as React from 'react'
import { vsprintf } from 'sprintf-js'

import { useSiteDefinition, i18n } from '@thg-commerce/enterprise-core'
import { formatPrice } from '@thg-commerce/enterprise-pricing'

import {
  Progress,
  ProgressBarContainer,
  Bar,
  IconGiftContainer,
  StyledIconGift,
  MetaContainer,
  UnqualifiedTotalWrapper,
  Meta,
} from './styles'

export const ProgressBar = (props: {
  currentAmountSpent: number
  isOpen: boolean
  additionalSpendToReach: {
    amount: number
    displayValue: string
  }
}) => {
  const { defaultLocale, defaultCurrency } = useSiteDefinition()

  const i18nText = {
    spend: i18n('basket.selectyoursample.unqualified.amount.total.text.part1'),
    orMore: i18n('basket.selectyoursample.unqualified.amount.total.text.part2'),
    toQualify: i18n(
      'basket.selectyoursample.unqualified.amount.total.text.part3',
    ),
    unqualifiedLeftAmount: i18n(
      'basket.selectyoursample.unqualified.amount.left.text',
    ),
  }

  return (
    <React.Fragment>
      <MetaContainer isOpen={props.isOpen} data-testid="not-qualified">
        <UnqualifiedTotalWrapper>
          <Meta>{i18nText.spend}</Meta>
          <Meta bold>
            {` ${vsprintf(i18nText.orMore, [
              formatPrice(
                defaultCurrency,
                props.currentAmountSpent + props.additionalSpendToReach.amount,
                defaultLocale,
              ),
            ])} `}
          </Meta>
          <Meta>{i18nText.toQualify}</Meta>
        </UnqualifiedTotalWrapper>
        <Meta bold>
          {`${props.additionalSpendToReach.displayValue} ${i18nText.unqualifiedLeftAmount}`}
        </Meta>
      </MetaContainer>
      <ProgressBarContainer>
        <Progress>
          <Bar
            completedRatio={
              (props.currentAmountSpent * 100) /
              (props.additionalSpendToReach.amount + props.currentAmountSpent)
            }
          />
        </Progress>
        <IconGiftContainer>
          <StyledIconGift />
        </IconGiftContainer>
      </ProgressBarContainer>
    </React.Fragment>
  )
}
