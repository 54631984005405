import * as React from 'react'
import { useMutation } from '@apollo/react-hooks'
import gql from 'graphql-tag'

import {
  EnterpriseContext,
  i18n,
  useBasketId,
  useHorizonSessionSettings,
  useTransmit,
} from '@thg-commerce/enterprise-core'
import { ProductImage } from '@thg-commerce/gravity-elements'

import { BasketData } from '@thg-commerce/enterprise-network/src/ApolloProvider/resolvers/Query/Basket/Basket'
import {
  SelectYourSampleMutationData,
  SelectYourSampleMutationVariables,
} from '@thg-commerce/enterprise-network/src/ApolloProvider/resolvers/Mutation/AddSelectYourSampleProductToBasket'
import {
  RemoveYourSampleMutationData,
  RemoveYourSampleMutationVariables,
} from '@thg-commerce/enterprise-network/src/ApolloProvider/resolvers/Mutation/RemoveSelectYourSampleProductFromBasket'
import { useBackendEventNormaliser } from '@thg-commerce/enterprise-metrics'
import { pushToDataLayer } from '@thg-commerce/enterprise-metrics/src/data_layer'

import { SelectYourSampleContext } from '../SelectYourSampleContext'
import {
  Product as TierProduct,
  ProductTitle,
  ProductContainer,
  InfoIcon,
} from './styles'
import { useBasket } from '@thg-commerce/enterprise-core/src/Basket/hooks/useBasketId'

export const ADD_SELECT_YOUR_SAMPLE_MUTATION = gql`
  mutation AddSelectYourSampleProductToBasket(
    $basketId: ID
    $selectYourSampleId: ID!
    $tierId: ID!
    $sku: SKU
    $settings: SessionSettings!
  ) {
    addSelectYourSampleProductToBasket(
      basketId: $basketId
      selectYourSampleId: $selectYourSampleId
      tierId: $tierId
      sku: $sku
      settings: $settings
    ) @client {
      id
    }
  }
`

export const REMOVE_SELECT_YOUR_SAMPLE_MUTATION = gql`
  mutation RemoveSelectYourSampleProductFromBasket(
    $basketId: ID
    $selectYourSampleId: ID!
    $tierId: ID!
    $sku: SKU
    $settings: SessionSettings!
  ) {
    removeSelectYourSampleProductFromBasket(
      basketId: $basketId
      selectYourSampleId: $selectYourSampleId
      tierId: $tierId
      sku: $sku
      settings: $settings
    ) @client {
      id
    }
  }
`

export interface ProductProps {
  products: BasketData['selectYourSample'][0]['tiers'][0]['products']
  selectedProducts: { sku: number }[]
  tierId: string
  isQualified: boolean
  isSelectable: boolean
}

export const Product = (props: ProductProps) => {
  const [basketId, setBasketId] = useBasketId()
  const { basket } = useBasket()
  const sessionSettings = useHorizonSessionSettings()
  const {
    selectYourSampleInteracted,
    setSelectYourSampleInteracted,
    informationModalPresenter,
  } = React.useContext(SelectYourSampleContext)

  const transmit = useTransmit()
  const normaliseBackendEvent = useBackendEventNormaliser()
  const { extensionsRef } = React.useContext(EnterpriseContext)
  const {
    value: [getExtensions],
  } = extensionsRef

  const [addSelectYourSampleProductToBasket] = useMutation<
    { addSelectYourSampleProductToBasket: SelectYourSampleMutationData },
    SelectYourSampleMutationVariables
  >(ADD_SELECT_YOUR_SAMPLE_MUTATION, {
    fetchPolicy: 'no-cache',
    onCompleted: (data) => {
      const extensions = getExtensions()

      data?.addSelectYourSampleProductToBasket &&
        setBasketId(data.addSelectYourSampleProductToBasket.id)

      transmit({
        type: 'cart_interaction_event',
        payload: normaliseBackendEvent({
          basket,
          eventData: {
            type: 'cart_interaction_event',
            subtype: 'free_gift_add',
          },
          rays: [extensions?.ray || ''],
          experiments: extensions?.experiments,
          requestData: {
            ...(extensions?.LoggerLinkData || {
              start_timestamp: Date.now(),
              duration_ms: 0,
            }),
            url: window.location.href,
          },
        }),
      })
    },
  })

  const [removeSelectYourSampleProductToBasket] = useMutation<
    { removeSelectYourSampleProductFromBasket: RemoveYourSampleMutationData },
    RemoveYourSampleMutationVariables
  >(REMOVE_SELECT_YOUR_SAMPLE_MUTATION, {
    fetchPolicy: 'no-cache',
    onCompleted: (data) => {
      data?.removeSelectYourSampleProductFromBasket &&
        setBasketId(data.removeSelectYourSampleProductFromBasket.id)
    },
  })

  const ariaLabel = i18n(
    'basket.selectyoursample.productinformation.aria.label',
  )

  const productBlocks = props.products.map((product) => {
    const isSelected = props.selectedProducts
      .map((selectedProduct) => selectedProduct.sku)
      .includes(product.sku)
    const isDisabled =
      !props.isQualified ||
      (!isSelected && !props.isSelectable) ||
      !product.inStock

    const [selectYourSampleId, tierId] = props.tierId.split(':')

    const mutationVariables = {
      basketId,
      tierId,
      selectYourSampleId,
      sku: product.sku.toString(),
      settings: sessionSettings,
    }

    const onProductClick = () => {
      if (isDisabled) return

      !selectYourSampleInteracted && setSelectYourSampleInteracted(true)

      if (isSelected) {
        removeSelectYourSampleProductToBasket({
          variables: mutationVariables,
        })

        pushToDataLayer({
          type: 'elysiumEvent',
          eventData: {
            eventAction: 'Removing Item',
            eventCategory: 'freeProductSelection',
            eventLabel: 'Gift Id',
            eventLabelValue: `${product.sku}${selectYourSampleId}${tierId}`,
          },
        })

        pushToDataLayer({
          type: 'elysiumEvent',
          eventData: {
            eventAction: 'Removed Simple Item',
            eventCategory: 'freeProductSelection',
            eventLabel: 'Gift Id',
            eventLabelValue: `${product.sku}${selectYourSampleId}${tierId}`,
          },
        })
      }

      if (!isSelected) {
        addSelectYourSampleProductToBasket({
          variables: mutationVariables,
        })

        pushToDataLayer({
          type: 'elysiumEvent',
          eventData: {
            eventAction: 'Adding Item',
            eventCategory: 'freeProductSelection',
            eventLabel: 'Gift Id',
            eventLabelValue: `${product.sku}${selectYourSampleId}${tierId}`,
          },
        })

        pushToDataLayer({
          type: 'elysiumEvent',
          eventData: {
            eventAction: 'Added Simple Item',
            eventCategory: 'freeProductSelection',
            eventLabel: 'Gift Id',
            eventLabelValue: `${product.sku}${selectYourSampleId}${tierId}`,
          },
        })
      }
    }

    return (
      <TierProduct
        key={product.sku}
        data-testid={`product-button-${product.sku}`}
        isSelected={isSelected}
        aria-label={product.title}
        onClick={onProductClick}
        disabled={isDisabled}
      >
        <ProductImage
          alt=""
          urls={{ largeProduct: product.images?.[0]?.thumbnail ?? '' }}
          width={[73]}
        />
        <ProductTitle>{product.title}</ProductTitle>
        <InfoIcon
          ariaLabel={ariaLabel}
          onClick={(e) => {
            pushToDataLayer({
              type: 'elysiumEvent',
              eventData: {
                eventAction: 'Clicked on Product Info',
                eventCategory: 'freeProductModal',
                eventLabel: 'product Id',
                eventLabelValue: `${product.sku}`,
              },
            })
            informationModalPresenter.current &&
              informationModalPresenter.current(product)
            e.stopPropagation()
          }}
        />
      </TierProduct>
    )
  })

  return <ProductContainer>{productBlocks}</ProductContainer>
}
