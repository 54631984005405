import * as React from 'react'

import { styled } from '@thg-commerce/gravity-theme'

import { Table } from './Table'
import { Heading } from './Heading'
import { Discount } from './Discount'
import { Checkout } from './Checkout'

const Container = styled.div`
  background-color: ${(props) => props.theme.colors.palette.greys.white};
`

export const Skeleton = () => {
  return (
    <Container>
      <Heading />
      <Table />
      <Discount />
      <Checkout />
    </Container>
  )
}
