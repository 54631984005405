import * as React from 'react'
import { vsprintf } from 'sprintf-js'

import { ImageThumbnailsProps } from '../types'
import {
  ProductImageThumbnailsWrapper,
  StyledThumbnailList,
  ProductImageWrapperListItem,
  StyledButton,
  StyledImage,
} from './styles'

export const ImageThumbnails = (props: ImageThumbnailsProps) => {
  if (props.urls.length === 0) {
    return null
  }

  const productThumbnails = props.urls.map((url, index) => {
    if (!url.url) {
      return null
    }

    // TODO: REBUILD-4597: change to use replaceAll after upgrade to node version > 15
    const escapedThumbnailButtonAriaLabel = props.thumbnailButtonAriaLabel.replace(
      /%(?![s])/g,
      '%%',
    )

    const ariaLabel = vsprintf(escapedThumbnailButtonAriaLabel, [
      `${index + 1}`,
    ])

    return (
      <ProductImageWrapperListItem
        key={url.url}
        direction={props.direction}
        fullScreen={props.fullScreen}
        size={props.size}
        aspectRatio={props.aspectRatio}
      >
        <StyledButton
          aria-label={ariaLabel}
          selected={index === props.selectedImageIndex}
          data-testid={`image-thumbnails-button-${index}`}
          onClick={() => {
            props.onSelect?.(index)
          }}
        >
          <StyledImage
            src={[
              {
                url: url.url,
                width: 70,
                imageSize: 70,
              },
            ]}
            alt={''}
            width="70"
            fullScreen={props.fullScreen}
            aspectRatio={props.aspectRatio}
            isAmp={false}
          />
        </StyledButton>
      </ProductImageWrapperListItem>
    )
  })

  return (
    <ProductImageThumbnailsWrapper
      direction={props.direction}
      fullScreen={props.fullScreen}
    >
      <StyledThumbnailList
        direction={props.direction}
        fullScreen={props.fullScreen}
      >
        {productThumbnails}
      </StyledThumbnailList>
    </ProductImageThumbnailsWrapper>
  )
}
