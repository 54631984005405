import gql from 'graphql-tag'

export const QubitProductsQuery = gql`
  query QubitProducts(
    $skus: [SKU!]!
    $currency: Currency!
    $shippingDestination: Country!
    $vipPriceEnabled: Boolean! = false
    $clickAndCollectEnabled: Boolean = false
    $productContentKeys: [String!] = [""]
  ) {
    productBlockList(
      skus: $skus
      currency: $currency
      shippingDestination: $shippingDestination
      vipPriceEnabled: $vipPriceEnabled
      clickAndCollectEnabled: $clickAndCollectEnabled
      productContentKeys: $productContentKeys
    ) @client {
      sku
      externalIdentifier
      url
      title
      brand
      imageUrl
      category
      reviews {
        total
        averageScore
      }
      price {
        defaultPrice
        minPrice
        maxPrice
      }
      rrp {
        defaultPrice
        minPrice
        maxPrice
      }
      inStock
    }
  }
`
