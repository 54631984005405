import * as React from 'react'

import {
  Facebook,
  Instagram,
  Pinterest,
  Spotify,
  TikTok,
  Twitter,
  YouTube,
} from './EmbedProviders'

export type ProviderType =
  | 'youtube'
  | 'facebook'
  | 'twitter'
  | 'instagram'
  | 'tiktok'
  | 'pinterest'
  | 'spotify'

export interface SocialEmbedProps {
  url: string
  provider: ProviderType
  isAmp: boolean
  width?: string
  height?: string
}

export const SocialEmbed = (props: SocialEmbedProps) => {
  switch (props.provider) {
    case 'youtube':
      return (
        <YouTube
          url={props.url}
          width={props.width}
          height={props.height}
          isAmp={props.isAmp}
        />
      )
    case 'facebook':
      return (
        <Facebook
          url={props.url}
          width={props.width}
          height={props.height}
          isAmp={props.isAmp}
        />
      )
    case 'twitter':
      return <Twitter url={props.url} isAmp={props.isAmp} />
    case 'instagram':
      return <Instagram url={props.url} isAmp={props.isAmp} />
    case 'tiktok':
      return <TikTok url={props.url} isAmp={props.isAmp} />
    case 'pinterest':
      return <Pinterest url={props.url} isAmp={props.isAmp} />
    case 'spotify':
      return <Spotify url={props.url} isAmp={props.isAmp} />
  }
}
